import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react'
import { useQuery } from '@apollo/client'
import { Table, Row, Col, Input, Spin } from 'antd'
import _escapeRegExp from 'lodash/escapeRegExp'
import couponCodesPaginationQuery from '../graphql/queries/couponCodesPagination.gql'
import CouponExport from './CouponsExport'
import CouponTypeSelect from './CouponTypeSelect'

const Coupons = inject('router')(({ router }) => {
  const { location } = router
  const { p: page, couponTypeId, couponCode } = location.query
  const { error, loading, data } = useQuery(couponCodesPaginationQuery, {
    variables: {
      ...(couponCode ? { code: `/${_escapeRegExp(couponCode)}/i` } : {}),
      couponCodeTypeId: couponTypeId,
      page: parseInt(page),
      perPage: 10,
    },
    fetchPolicy: 'network-only',
  })

  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }

  const { items, pageInfo } = data?.couponCodesPagination || {}
  const { currentPage, itemCount, perPage } = pageInfo || {}
  const couponTypeName = couponTypeId ? items[0]?.couponCodeType?.name : 'All Coupons'
  const handleCouponTypeChange = (couponTypeId) => (location.query = { ...(couponTypeId ? { couponTypeId } : {}) })
  const handleSearch = (couponCode) => (location.query = { ...location.query, couponCode, p: 1 })
  const handlePagination = (page) => (location.query = { ...location.query, p: page })

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={11}>
          <FormattedMessage id='app.couponCodeTypeId' defaultMessage='Coupon Code type ID' />
          <span>: </span>
          <CouponTypeSelect onChange={handleCouponTypeChange} couponTypeId={couponTypeId} allCouponType />
        </Col>

        <Col sm={24} md={11}>
          <FormattedMessage id='app.couponCode' defaultMessage='Coupon Code' />
          <span>: </span>
          <Input.Search enterButton allowClear onSearch={handleSearch} style={{ width: 240, verticalAlign: 'middle' }} />
        </Col>

        <Col sm={24} md={2} align='right'>
          <CouponExport couponCodeTypeId={couponTypeId} couponTypeName={couponTypeName} />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table
            rowKey='_id'
            scroll={{ x: true }}
            dataSource={items}
            loading={loading}
            pagination={{
              pageSize: perPage,
              current: currentPage,
              total: itemCount,
              onChange: handlePagination,
            }}
          >
            <Table.Column
              title={<FormattedMessage id='app.couponType' defaultMessage='Coupon type' />}
              key='couponCodeType.name'
              dataIndex={['couponCodeType', 'name']}
            />

            <Table.Column title={<FormattedMessage id='app.couponCode' defaultMessage='Coupon Code' />} key='code' dataIndex={['code']} />

            <Table.Column title={<FormattedMessage id='app.couponMaxUse' defaultMessage='Max use per coupon' />} key='copies' dataIndex={['copies']} />

            <Table.Column
              title={<FormattedMessage id='app.couponMaxUsePerUser' defaultMessage='Max use per user (email)' />}
              key='copiesPerUser'
              dataIndex={['copiesPerUser']}
            />

            <Table.Column
              title={<FormattedMessage id='app.orderCoupons' defaultMessage='Order Coupons' />}
              key='orderCount'
              dataIndex={['orderCount']}
              render={(orderCount, { _id, couponCodeTypeId }) => <Link to={`/coupon-types/${couponCodeTypeId}/coupons/${_id}`}>{orderCount}</Link>}
            />
          </Table>
        </Col>
      </Row>
    </>
  )
})

export default Coupons
