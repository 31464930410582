import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { message, Select } from 'antd'
import { useQuery } from '@apollo/client'
import couponCodeTypesPaginationQuery from '../graphql/queries/couponCodeTypesPagination.gql'
import _escapeRegExp from 'lodash/escapeRegExp'

const CouponTypeSelect = ({ onChange, couponTypeId, allCouponType }) => {
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(couponCodeTypesPaginationQuery, {
    variables: {
      name: search,
    },
  })

  if (error) {
    return message.error(error)
  }

  const handleSearch = (input) => (input ? setSearch(`/${_escapeRegExp(input)}/i`) : setSearch())

  return (
    <Select
      showSearch
      loading={loading}
      filterOption={false}
      onSearch={handleSearch}
      onChange={onChange}
      value={couponTypeId || null}
      style={{ width: 220, marginBottom: '1em' }}
    >
      {allCouponType && (
        <Select.Option key={'all'} value={null}>
          <FormattedMessage id='app.allCouponType' defaultMessage='All Coupon type' />
        </Select.Option>
      )}
      {data?.couponCodeTypesPagination?.items?.map((item) => (
        <Select.Option key={item._id} value={item._id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CouponTypeSelect
