import styled from 'styled-components'

export const MainMenu = styled.div`
.pro-sidebar > .pro-sidebar-inner {
  // background: #FFFFFF; 
  /* background: rgb(40 77 165); */
  background: linear-gradient(180deg,#bd188b 0%,#7d1c7d 35%,#411a6f 100%);
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: -35px;
  padding-bottom: -35px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  max-height: 100vh;
  // overflow-y: auto;
  background-color: #fff;
  // padding-left: 20px;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 8%) 0px 6px 16px, rgb(0 0 0 / 5%) 0px 9px 28px 8px;
  // inset: 0px auto auto 0px;
  // position: relative;
  // transform: translate3d(85px, 0px, 0px);
  // height: ${props => `${props.screenHeight}px`};
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background: #2b2b2b00;
}
.pro-sidebar.collapsed {
  width: 62px;
  min-width: 62px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
  border-radius: 8px;
  width: 48px;
  height: 48px;
  margin: auto;
}
.pro-sidebar.collapsed .pro-menu > ul > .active.pro-menu-item::before {
  background: #F9A01B;
  opacity: 0.12;
}
.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  overflow-y: hidden;
}
.pro-sidebar .pro-menu .pro-menu-item {
  zoom: 1.1;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  // display: block;
  padding: 0;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon {
  height: auto;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  width: 44px;
  margin-right: 0;
  line-height: normal;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  // text-align: center;
  margin-left: 5px;
}
.mainiconmenu {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.34);  
}
.mainiconmenu-fa {
  margin: 5px 0;
  font-size: 18px;
  color: white;
}
.menu-icon{
  margin: 10px;
  color: #fff;
  font-size: 24px;
}
.pro-icon svg {
  margin-top: 3px;
}
.active.pro-menu-item .menu-title {
  color: white;
  /* color: #F9A01B; */
  // padding: 5px;
}
.menu-title {
  font-size: 9px;
  /* color: rgba(0, 0, 0, 0.34); */
  color: white;
  # font-family: 'Roboto';
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  position: relative;
  bottom: 10px;
  // margin: 10px;
  // padding: 5px;
  // margin-bottom: 15px;
}
.menu-item {
  font-size: 13px;
  color: #000;  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: unset;
}
.menu-item-mobile {
  font-size: 12px;
  margin: 10px 5px;
  color: #E8E2E1;  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
  .mobile {
    // border-right: 10px solid #EAEAEA;
    // padding: 10px solid #EAEAEA;
    position: fixed;
    height: 100%;
    .logo {
      padding-left: 7px;
      padding-top: 23px;
      margin-bottom: 10px;
      img {
        width: 48px;
        height: auto;
      }
    }
    .footer{
      padding: 10px;
    } 
  }

  @media only screen and (max-width: 750px) {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    .pro-sidebar > .pro-sidebar-inner {
      // background: #FFFFFF; 
      /* background: rgb(40 77 165); */
      background: #2F2F2F;
    }
    .menu-item {
      font-size: 12px;
      color: #E8E2E1;  
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }
    .menu-title {
      font-size: 12px;
      margin: 10px 5px;
      color: #E8E2E1;  
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }
    .menu-sub {
      font-size: 13px;
      // color: #fff;  
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }
    .menu-icon {
      // color: #fff;
      font-size: 20px;
    }
  .mobile {
    /* background: #2F2F2F; */
    /* padding-right: 30px; */
    /* position: fixed; */
    /* height: 100%; */
    /* height: ${props => `${props.screenHeight}px`}; */
    /* z-index: 101; */
    /* width: 286px; */
    .shiftMiddle {
      /* position: relative;
      top: 15%; */
    }
    span.menuTriggerIcon {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 1;
    }
    .ant-menu {
      color: rgba(255,255,255,0.7);
    }
    .ant-menu.ant-menu-sub {
      background: unset;
    }
    .pro-sidebar > .pro-sidebar-inner {
      background: #2F2F2F;
    }
    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
      padding: 0;
    }
    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
      border-right: unset;
    }
    svg.menuicon {
      position: relative;
      top: 5px;
    }
    span.menutitle {
      margin-left: 16px;
    }
    li.ant-menu-item a {
      color: rgba(255, 255, 255, 0.7);
      font-family: Roboto;
      font-size: 16px;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: rgba(255, 255, 255, 0.08);
    }
  
    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
      border-right: 3px solid #FFFFFF;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a{
      color: #FFFFFF;
    }
    ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
      background: #2F2F2F;
    }
    li.ant-menu-item.menulogoitem {
      height: auto;
    }
    .menulogout {
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      font-family: Roboto;
      padding: 10px;
      /* position: absolute;
      bottom: 21px; */
      /* bottom: calc(25% + 21px); */
      /* left: 30px; */
      span.menu-title {
        margin-left: 16px;
      }
    }
    .logo {
      padding-left: 7px;
      padding-top: 23px;
      margin-bottom: 10px;
      img {
        width: 65px;
        height: auto;
      }
    }
  }
  }
`