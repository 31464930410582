import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { inject } from 'mobx-react'
import { Table, Button, Row, Col, message } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { compose } from 'recompose'
import moment from 'moment'
import * as XLSX from 'xlsx'
import couponCodeQuery from '../graphql/queries/couponCode.gql'
import couponCodeTypeQuery from '../graphql/queries/couponCodeType.gql'
import CouponSelect from './CouponSelect'
import CouponTypeSelect from './CouponTypeSelect'

const CouponUsedList = compose(inject('router'))(({ router, match }) => {
  const couponTypeId = match?.params?.couponTypeId
  const couponId = match?.params?.couponId
  let error = {},
    loading = {},
    data = []

  if (couponId) {
    const {
      error: couponCodeError,
      loading: couponCodeLoading,
      data: couponCodeData,
    } = useQuery(couponCodeQuery, {
      variables: {
        _id: couponId,
      },
      fetchPolicy: 'network-only',
    })
    error = couponCodeError
    loading = couponCodeLoading
    data = couponCodeData?.couponCode
  }

  if (!couponId) {
    const {
      error: couponCodeTypeError,
      loading: couponCodeTypeLoading,
      data: couponCodeTypeData,
    } = useQuery(couponCodeTypeQuery, {
      variables: {
        _id: couponTypeId,
      },
      fetchPolicy: 'network-only',
    })
    error = couponCodeTypeError
    loading = couponCodeTypeLoading
    data = couponCodeTypeData?.couponCodeType
  }

  if (error) {
    return message.error(error)
  }

  if (loading) {
    return 'Loading'
  }

  const handleCouponTypeChange = (input) => router.push(`/coupon-types/${input ?? 'all'}/coupons`)
  const handleCouponChange = (input) => router.push(`/coupon-types/${couponTypeId}/coupons/${input ?? ''}`)
  const exportToCSV = (apiData, fileName = 'unknown.xlsx', SheetNames) => {
    const ws = apiData.map((item) => XLSX.utils.json_to_sheet(item))
    const Sheets = {}
    SheetNames.forEach((item, index) => (Sheets[item] = ws[index]))
    const wb = { Sheets, SheetNames }
    XLSX.writeFile(wb, fileName)
  }

  const couponTypeName = data?.name || data?.couponCodeType?.name
  const couponData = data?.couponUsed || data?.orders || []
  const couponDiscountType = `${data?.couponCodeType?.discount || data.discount} ${
    data?.couponCodeType?.discountType || data.discount === 'percentage' ? '%' : 'THB'
  }`
  const exportData = couponData.map((item) => {
    return {
      Email: item?.clientEmail,
      'Transaction Date': moment(item?.completedAt).format('DD-MM-YYYY'),
      'Order ID': item?.paymentId,
      'Event ID': item?.eventId,
      'Event Title': item?.event?.title,
      Amount: item?.amount,
      'Discount Type': couponDiscountType,
      Discount: item?.discount,
      'After Discount': item?.payAmount,
      'Coupon Code': item?.couponCodes.map((el) => el.code).join('\n'),
      Method: item?.method,
    }
  })

  return (
    <>
      <Row>
        <Col sm={24} md={11}>
          <FormattedMessage id='app.couponCodeTypeId' defaultMessage='Coupon Code type ID' />
          <span>: </span>
          <CouponTypeSelect onChange={handleCouponTypeChange} couponTypeId={couponTypeId} />
        </Col>

        <Col sm={24} md={11}>
          <FormattedMessage id='app.couponCode' defaultMessage='Coupon Code' />
          <span>: </span>
          <CouponSelect onChange={handleCouponChange} couponTypeId={couponTypeId} couponId={couponId} />
        </Col>

        <Col sm={24} md={2} align='right'>
          <Button
            icon={<FileExcelOutlined />}
            type='primary'
            size='small'
            onClick={() => exportToCSV([exportData], `used_${couponTypeName}_${moment().format('DD_MM_YYYY')}.xlsx`, [couponTypeName])}
            style={{ margin: '0.3em 1em 1em 0' }}
          >
            <FormattedMessage id='app.export' defaultMessage='Export' />
          </Button>
        </Col>
      </Row>

      <Row>
        <Table rowKey='_id' scroll={{ x: true }} dataSource={couponData} loading={loading}>
          <Table.Column title={<FormattedMessage id='app.email' defaultMessage='Email' />} key='clientEmail' dataIndex={['clientEmail']} />

          <Table.Column
            title={<FormattedMessage id='app.orderCompletedDate' defaultMessage='Payment Date' />}
            key='Payment Date'
            dataIndex={['completedAt']}
            render={(date) => moment(date).format('DD-MM-YYYY')}
          />

          <Table.Column title={<FormattedMessage id='app.event' defaultMessage='Event' />} key='event' dataIndex={['event', 'title']} />

          <Table.Column title={<FormattedMessage id='app.amount' defaultMessage='Amount' />} key='amount' dataIndex={['amount']} />

          <Table.Column title={<FormattedMessage id='app.discount' defaultMessage='Discount' />} key='discount' dataIndex={['discount']} />

          <Table.Column title={<FormattedMessage id='app.payAmount' defaultMessage='Pay Amount' />} key='payAmount' dataIndex={['payAmount']} />

          <Table.Column title={<FormattedMessage id='app.paymentMethod' defaultMessage='Payment Method' />} key='method' dataIndex={['method']} />

          <Table.Column
            title={<FormattedMessage id='app.couponCode' defaultMessage='Coupon Code' />}
            key='couponCode'
            dataIndex={['couponCodes']}
            render={(couponCode) => couponCode.map((el) => el.code).join('\n')}
          />

          <Table.Column
            title={<FormattedMessage id='app.orderLink' defaultMessage='Order Link' />}
            key='orderLink'
            dataIndex={['orderLink']}
            render={(orderLink) => {
              return (
                <a href={orderLink}>
                  <FormattedMessage id='app.link' defaultMessage='Link' />
                </a>
              )
            }}
          />
        </Table>
      </Row>
    </>
  )
})

export default CouponUsedList
