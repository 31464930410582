import React, { useState } from 'react'
import { Row, Col, Select, DatePicker, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { inject } from 'mobx-react'
import { useQuery } from '@apollo/client'
import { ResponsiveLine } from '@nivo/line'
import PhotographerInfo from './PhotographerInfo'
import OrganizerInfo from './OrganizerInfo'
import monthSummaryQuery from '~/graphql/queries/monthSummary.gql'
import daySummaryQuery from '~/graphql/queries/daySummary.gql'
// import totalOrganizerReceivedQuery from '~/graphql/queries/totalOrganizerReceived.gql'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DashboardStats from './DashboardStats'
import DashboardDayStats from './DashboardDayStats'
import UserPermission from '~/components/UserPermission'
import { Link } from 'react-router-dom'
import BrokerInfo from './BrokerInfo'
import '../styles/dashboard.css'
import useTotalBalance from '../hooks/useTotalBalance'

const Dashboard = ({ router }) => {
  const intl = useIntl()
  const { error, loading, data } = useQuery(monthSummaryQuery, {
    variables: {
      page: 1,
      perPage: 1000
    }
  })

  const { error: dayErr, loading: dayLoading, data: dayData } = useQuery(daySummaryQuery)
  const totalBalance = useTotalBalance()
  const [view, setView] = useState('month')
  const [startYear, setStartYear] = useState(2016)
  const [endYear, setEndYear] = useState(moment().year())
  const [startMonth, setStartMonth] = useState(0)
  const [endMonth, setEndMonth] = useState(11)
  const [startDay, setStartDay] = useState(moment().subtract(30, 'days'))
  const [endDay, setEndDay] = useState(moment())
  const { monthSummary } = data || {}
  const { daySummary } = dayData || {}
  // const { totalOrganizerReceived } = totalOrganizerReceivedData || {}
  let { items } = monthSummary || {}
  // let { balance, earnings, organizerEarnings } = balanceAndEarnings || {}

  const monthsShort = moment.monthsShort()
  const months = moment.months()

  const yearOptions = []
  for (let i = 2016; i < moment().year() + 1; i++) {
    yearOptions.push(i)
  }

  const currentYear = moment().year()
  const lastYear = moment().year() - 1
  const currentYearIncome = items?.filter(item => item.year === currentYear).reduce((r, a) => r + a.totalSales, 0)
  const lastYearIncome = items?.filter(item => item.year === lastYear).reduce((r, a) => r + a.totalSales, 0)

  const currentMonthIncome = items?.filter(item => item.year === currentYear && item.month === moment().month() + 1).reduce((r, a) => r + a.totalSales, 0)
  const lastMonthIncome = items?.filter(item => item.year === currentYear && item.month === moment().month()).reduce((r, a) => r + a.totalSales, 0)
  const secondLastMonthIncome = items?.filter(item => item.year === currentYear && item.month === moment().month() - 1).reduce((r, a) => r + a.totalSales, 0)

  const currentMonthLastYearIncome = items?.filter(item => item.year === lastYear && item.month === moment().month() + 1).reduce((r, a) => r + a.totalSales, 0)

  let saleItems = items?.map(item => item).reverse()
  // console.log(saleItems)
  let tobeGraphData = []
  let graphData
  let currentDayIncome
  let currentDayLastYearIncome

  if (view === 'day') {
    currentDayIncome = daySummary?.find(({ _id }) => moment(_id).isSame(moment(), 'day'))?.totalSales
    currentDayLastYearIncome = daySummary?.find(({ _id }) => moment(_id).isSame(moment().year(lastYear), 'day'))?.totalSales
    // console.log(startDay.year())
    // console.log(daySummary)
    for (let i = 0; i < endDay.diff(startDay, 'days') + 1; i++) {
      // console.log(moment(startDay).add(i, 'days'), 'days')
      const currentDay = moment(startDay).add(i, 'days')
      const tmp = daySummary?.find(({ _id }) => moment(_id).isSame(currentDay, 'day'))
      // console.log(tmp)]
      tobeGraphData.push({
        id: currentDay.year(),
        data: {
          x: currentDay.format('DD/MM'),
          y: tmp ? tmp.totalSales : null
        }
      })
    }

    graphData = tobeGraphData.reduce((r, a) => {
      // console.log('r', r)
      // console.log('a', a)
      if (a.id === lastYear) {
        r[0].data.push(a.data)
      } else if (a.id === currentYear) {
        r[1].data.push(a.data)
      }
      return r
    }, [{
      id: lastYear,
      data: []
    }, {
      id: currentYear,
      data: []
    }])
  }
  if (view === 'month') {
    for (let i = lastYear; i < currentYear + 1; i++) {
      for (let j = 0; j < 12; j++) {
        if (startMonth <= j && endMonth >= j) {
          const tmp = saleItems?.find(item => item.year === i && item.month === j + 1)
          tobeGraphData.push({
            id: i,
            data: {
              x: monthsShort[j],
              y: tmp ? tmp.totalSales : null
            }
          })
        }
      }
    }
    graphData = tobeGraphData.reduce((r, a) => {
      // console.log('r', r)
      // console.log('a', a)
      if (a.id === lastYear) {
        r[0].data.push(a.data)
      } else if (a.id === currentYear) {
        r[1].data.push(a.data)
      }
      return r
    }, [{
      id: lastYear,
      data: []
    }, {
      id: currentYear,
      data: []
    }])
  } else if (view === 'year') {
    for (let i = startYear; i < endYear + 1; i++) {
      const tmp = saleItems?.filter(item => item.year === i)
      // console.log(tmp)
      tobeGraphData.push({
        id: 'All time',
        data: {
          x: i,
          y: tmp?.reduce((r, a) => {
            return r + a.totalSales
          }, 0)
        }
      })
    }
    graphData = tobeGraphData.reduce((r, a) => {
      if (a.data.y !== 0) r[0].data.push(a.data)
      else {
        r[0].data.push({
          x: a.data.x,
          y: null
        })
      }
      return r
    }, [{
      id: 'All time',
      data: []
    }])
  }

  if (error || dayErr) {
    return 'error'
  }

  if (loading || dayLoading) {
    return 'loading...'
  }

  return (<UserPermission grantedTo={['superadmin', 'admin', 'accounting']}>
    <div>
      <Tooltip title="ยอดค้างชำระส่วนแบ่ง">
        <h1>Balance</h1> <InfoCircleOutlined />
      </Tooltip>
      <p>
        <span style={{ color: '#269afb' }}>฿</span>
        {' '}
        <span className="big-blue">
          {totalBalance
            ? intl.formatNumber(totalBalance, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : '...'
          }
        </span>
      </p>

      <Row type="flex" gutter={24}>
        <Col xs={24} md={8}>
          <Link to="/events:balance:organizer">
            <div style={{ color: '#000' }} >
              <p>Organizers</p>
              <OrganizerInfo />
            </div>
          </Link>
        </Col>
        <Col xs={24} md={8}>
          {/* <Link to="/"> */}
          <div style={{ color: '#000' }} >
            <p>Photographers</p>
            <PhotographerInfo />
          </div>
          {/* </Link> */}
        </Col>
        <Col xs={24} md={8}>
          <Link to="/events:balance:broker">
            <div style={{ color: '#000' }} >
              <p>Brokers</p>
              <BrokerInfo />
            </div>
          </Link>
        </Col>
      </Row>
      <h1 style={{ marginTop: '2em' }}>Realtime Performance</h1>
      <Row type="flex" >
        <Col xs={24} style={{ height: '450px' }}>
          <Row type="flex" style={{ marginBottom: '20px' }}>
            <Col xs={12} >
              <div>
                <div style={{ borderRadius: '50%', background: '#269afb', width: '20px', height: '20px', textAlign: 'center', display: 'inline-block', marginRight: '5px' }}>
                  <FontAwesomeIcon icon={['fas', 'wallet']} transform="up-2" style={{ color: '#fff', fontSize: '12px' }} />
                </div>
                <p style={{ display: 'inline-block' }}>
                  Gross Income
                </p>
                {view === 'year' && <>
                  <p><span style={{ color: '#269afb' }}>฿</span> <span className="big-blue">{intl.formatNumber(currentYearIncome || 0)}</span></p>
                  <p>
                    {lastYearIncome < currentYearIncome
                      ? <FontAwesomeIcon icon={['fas', 'caret-up']} style={{ color: '#52c41b' }} />
                      : <FontAwesomeIcon icon={['fas', 'caret-down']} style={{ color: 'red' }} />
                    }
                    <span className="sub-data" style={{ marginLeft: '5px' }}>{lastYearIncome !== 0 ? (((currentYearIncome - lastYearIncome) / lastYearIncome) * 100).toFixed(2) : 'N/A'}% | {intl.formatNumber(lastYearIncome || 0)}</span>
                  </p>
                </>}
                {view === 'month' && <>
                  <p><span style={{ color: '#269afb' }}>฿</span> <span className="big-blue">{intl.formatNumber(currentYearIncome || 0)}</span></p>
                  <p>
                    {currentMonthLastYearIncome < currentMonthIncome
                      ? <FontAwesomeIcon icon={['fas', 'caret-up']} style={{ color: '#52c41b' }} />
                      : <FontAwesomeIcon icon={['fas', 'caret-down']} style={{ color: 'red' }} />
                    }
                    <span className="sub-data" style={{ marginLeft: '5px' }}>{currentMonthLastYearIncome !== 0 ? (((currentMonthIncome - currentMonthLastYearIncome) / currentMonthLastYearIncome) * 100).toFixed(2) : 'N/A'}% | {intl.formatNumber(currentMonthLastYearIncome || 0)}</span>
                  </p>
                </>}
                {view === 'day' && <>
                  <p><span style={{ color: '#269afb' }}>฿</span> <span className="big-blue">{intl.formatNumber(currentDayIncome || 0)}</span></p>
                  <p>
                    {currentDayLastYearIncome < currentDayIncome
                      ? <FontAwesomeIcon icon={['fas', 'caret-up']} style={{ color: '#52c41b' }} />
                      : <FontAwesomeIcon icon={['fas', 'caret-down']} style={{ color: 'red' }} />
                    }
                    <span className="sub-data" style={{ marginLeft: '5px' }}>{currentDayLastYearIncome !== 0 ? (((currentDayIncome - currentDayLastYearIncome) / currentDayLastYearIncome) * 100).toFixed(2) : 'N/A'}% | {intl.formatNumber(currentDayLastYearIncome || 0)}</span>
                  </p>
                </>}
              </div>
            </Col>
            <Col xs={12} style={{ textAlign: 'right' }} >
              <Select
                defaultValue={view}
                style={{
                  width: '158px'
                }}
                onSelect={(value) => setView(value)}
              >
                <Select.Option key={'day'} value={'day'}>
                  Day
                </Select.Option>
                <Select.Option key={'month'} value={'month'}>
                  Month
                </Select.Option>
                <Select.Option key={'year'} value={'year'}>
                  Year
                </Select.Option>
              </Select>
              <br /><br />
              {view === 'day' && <>
                <DatePicker placeholder="day starts" onChange={(value) => setStartDay(value)} format="DD/MM/YYYY" defaultValue={startDay} style={{ marginRight: '10px' }} />
                <DatePicker placeholder="day ends" onChange={(value) => setEndDay(value)} format="DD/MM/YYYY" defaultValue={endDay} />
                <p>(group by payment completed day)</p>
              </>}
              {view === 'month' && <>
                <Select
                  style={{
                    width: '158px',
                    marginRight: '10px'
                  }}
                  placeholder="month starts"
                  defaultValue={startMonth}
                  onSelect={(value) => setStartMonth(value)}
                >
                  {months.map((item, index) => <Select.Option key={index} value={index}>
                    {item}
                  </Select.Option>)}
                </Select>
                <Select
                  style={{
                    width: '158px'
                  }}
                  placeholder="month ends"
                  defaultValue={endMonth}
                  onSelect={(value) => setEndMonth(value)}
                >
                  {months.map((item, index) => <Select.Option key={index} value={index}>
                    {item}
                  </Select.Option>)}
                </Select>
              </>}
              {view === 'year' && <>
                <Select
                  style={{
                    width: '158px',
                    marginRight: '10px'
                  }}
                  placeholder="year starts"
                  defaultValue={startYear}
                  onSelect={(value) => setStartYear(value)}
                >
                  {yearOptions.map(item => <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>)}
                </Select>
                <Select
                  style={{
                    width: '158px'
                  }}
                  placeholder="year ends"
                  defaultValue={endYear}
                  onSelect={(value) => setEndYear(value)}
                >
                  {yearOptions.map(item => <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>)}
                </Select>
              </>}
            </Col>
          </Row>

          {graphData && <ResponsiveLine
            data={graphData}
            height={300}
            colors={view === 'year' ? ['#269afb'] : ['#FB8226', '#269afb']}
            // margin={{ top: 10, right: 110, bottom: 80, left: 60 }}
            margin={{ top: 50, right: 110, bottom: 50, left: 120 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', reverse: false }}
            yFormat={value => intl.formatNumber(value)}
            enableArea={true}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: view === 'month'
                ? 'Month'
                : view === 'year'
                  ? 'Year'
                  : view === 'day'
                    ? 'Day'
                    : null,
              legendOffset: 36,
              legendPosition: 'middle'
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Income',
              legendOffset: -100,
              legendPosition: 'middle',
              format: value => intl.formatNumber(value)
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            enableGridX={false}
            enableGridY={false}
            // pointColor={{ from: 'color', modifiers: [] }}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />}
        </Col>
        {view === 'year' && <>
          <DashboardStats
            title={`Current Year ${currentYear}`}
            year={currentYear}
            income={currentYearIncome}
            theme={'big-blue'}
          />
          <DashboardStats
            title={lastYear}
            year={lastYear}
            income={lastYearIncome}
            theme={'big-orange'}
          />
        </>}
        {view === 'month' && <>
          <DashboardStats
            title={`Current Month ${moment().format('MMMM YYYY')}`}
            year={currentYear}
            month={moment().month()}
            income={currentMonthIncome}
            theme={'big-blue'}
          />
          <DashboardStats
            title={moment().subtract(1, 'months').format('MMMM YYYY')}
            year={currentYear}
            month={moment().month() - 1}
            income={lastMonthIncome}
            theme={'big-blue'}
          />
          <DashboardStats
            title={moment().subtract(2, 'months').format('MMMM YYYY')}
            year={currentYear}
            month={moment().month() - 2}
            income={secondLastMonthIncome}
            theme={'big-blue'}
          />
        </>}
        {view === 'day' && <Col xs={24}>
          <Row type="flex" style={{ background: '#F3FBFF', marginBottom: '10px' }}>
            <Col xs={5} ><span style={{ fontWeight: 'bold' }}>Date</span></Col>
            <Col xs={5} ><span >% Avg. Sold/Uploaded Photos</span></Col>
            <Col xs={5} ><span >Avg. Photo/Transaction</span></Col>
            <Col xs={4} ><span >% Paid Downloads</span></Col>
            {/* <Col xs={3} ><span >% Partner Events</span></Col>
            <Col xs={3} ><span >% Non-Partner Events</span></Col> */}
            <Col xs={5} ><span >% T.R. Share</span></Col>
          </Row>
          <Row type="flex">
            {Array.from(graphData).reverse().map(item => Array.from(item.data).reverse().map(data => <DashboardDayStats
              key={`${data.x}/${item.id}`}
              date={`${data.x}/${item.id}`}
              income={data.y}
            />))}
          </Row>
        </Col>}
      </Row>
    </div>
  </UserPermission>)
}

export default inject('router')(Dashboard)
