import React from 'react'
// import useForm from 'react-hook-form'
import { Button, List, Spin, Typography } from 'antd'
import { FormattedNumber, injectIntl, FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
// import QRCode from 'qrcode'
import { compose } from 'recompose'
import moment from 'moment'
import _get from 'lodash/get'
// import purchaseCreditsMutation from '~/graphql/mutations/purchaseCredits.gql'
import currentAdminUserCreditsQuery from '~/graphql/queries/currentAdminUserCredits.gql'
import { Link } from 'react-router-dom'
// const packages = [1000, 2000, 3000, 4000, 5000]
// const handleReload = () => {
//   window.scrollTo(0, 0)
//   window.location.reload()
// }
const CreditHistory = compose(injectIntl)(({ intl }) => {
  // const [purchaseCredits] = useMutation(purchaseCreditsMutation)
  const { error, loading, data } = useQuery(currentAdminUserCreditsQuery, { fetchPolicy: 'network-only' })
  // console.log({ data })
  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  const orders = _get(data, 'currentAdminUser.creditOrders', [])
  const usages = _get(data, 'currentAdminUser.creditUsages', [])
  const recentCreditBalance = _get(data, 'currentAdminUser.recentCreditBalance', {})
  const records = [
    ...orders.map(order => ({
      type: order.method,
      date: order.completedAt,
      creditAmount: order.creditAmount,
      meta: order.meta || {}
    })),
    ...usages.map(usage => ({
      type: 'upload',
      date: usage.updatedAt,
      creditAmount: -usage.creditAmount,
      meta: usage.meta || {}
    }))
  ].sort((a, b) => moment(b.date).diff(a.date))
  const total = _get(data, 'currentAdminUser.creditBalance.total')
  return <React.Fragment>
    <div className="rounded-container" style={{ textAlign: 'center' }}>
      <Typography.Title level={3}>
        <FormattedMessage id="app.totalCredits" defaultMessage="Total Credits" />
      </Typography.Title>
      <Typography.Text strong style={{ fontSize: '3em' }}>
        <FormattedNumber value={total} />
      </Typography.Text>
      <Typography.Paragraph>
        <Button type="primary">
          <Link to="/credits/buy"><FormattedMessage id="app.buyCredits" defaultMessage="Buy Credits" /></Link>
        </Button>
      </Typography.Paragraph>
    </div>
    <div className="rounded-container">
      <List
        header={<Typography.Title level={3}>
          <FormattedMessage id="app.recentCreditSummary" defaultMessage="Past week's credit activity" />
        </Typography.Title>}
      >
        <List.Item key="credit">
          <List.Item.Meta
            title={<FormattedMessage id="app.incomingCredits" defaultMessage="Credits Added" />}
          />
          {/* <Button type="primary" onClick={() => handleCreateOrder(creditAmount)}>
              <FormattedNumber value={creditAmount / 1000 * 50} />
              THB
            </Button> */}
          <Typography.Text strong style={{ color: '#52c41a' }}>
          +<FormattedNumber value={recentCreditBalance.credit} />
          </Typography.Text>
        </List.Item>
        <List.Item key="debit">
          <List.Item.Meta
            title={<FormattedMessage id="app.outgoingCredits" defaultMessage="Credits Used" />}
          />
          <Typography.Text strong style={{ color: '#f5222d' }}>
          -<FormattedNumber value={recentCreditBalance.debit} />
          </Typography.Text>
        </List.Item>
      </List>
    </div>
    <div className="rounded-container">
      {/* <Typography.Title level={3}>
        <FormattedMessage id="app.recentCredits" defaultMessage="Recent credit activity" />
      </Typography.Title> */}
      <List
        header={
          <Typography.Title level={3}>
            <FormattedMessage id="app.recentCredits" defaultMessage="Recent credit activity" />
          </Typography.Title>
        }
      >
        {records.map(record => (
          console.log(record.meta) ||
          <List.Item key={`${record.type}:${record.date}`}>
            <List.Item.Meta
              title={<FormattedMessage id={`app.creditTransaction.types.${record.type}`} values={record.meta} defaultMessage="Credit Transaction Type" />}
              description={moment(record.date).format('LLL')}
            />
            {/* <Button type="primary" onClick={() => handleCreateOrder(creditAmount)}>
              <FormattedNumber value={creditAmount / 1000 * 50} />
              THB
            </Button> */}
            <Typography.Text strong style={{ color: record.creditAmount > 0 ? '#52c41a' : '#f5222d' }}>
              {record.creditAmount > 0 && '+'}<FormattedNumber value={record.creditAmount} />
            </Typography.Text>
          </List.Item>
        ))}
      </List>
    </div>
  </React.Fragment>
})
// #52c41a green
// #f5222d red
export default CreditHistory
