import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
// import path from 'path'
// import url from 'url'
// import { Form } from 'antd'
import { useMutation } from '@apollo/client'
// import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import _get from 'lodash/get'
import cloudUploadMutation from '~/graphql/mutations/cloudUpload.gql'
import loadImage from 'blueimp-load-image'
import CloudImageUploader from './CloudImageUploader'

const FrameImageInput = React.forwardRef(({ portraitPreviewUrl, landscapePreviewUrl, onChange, value = {} }, ref) => {
  console.log({ portraitPreviewUrl, landscapePreviewUrl })
  const [upload] = useMutation(cloudUploadMutation)
  const [loading, setLoading] = useState(false)
  const { current: _value } = useRef(value)
  const { framePortraitUrl, frameLandscapeUrl } = _value
  // const { getFieldDecorator, setFieldsValue } = form
  // const framePortraitUrl = getFieldValue('framePortraitUrl')
  // const frameLandscapeUrl = getFieldValue('frameLandscapeUrl')
  const onChangeFn = (field) => (v) => {
    console.log('changed', field)
    _value[field] = v
    onChange(_value)
  }
  const fileMiddleware = async (file, next) => {
    setLoading(true)
    next(file) // TODO: Promise.all?
    const { originalWidth, originalHeight } = await loadImage(file, {
      orientation: true
    })
    if (originalWidth >= 4800 || originalHeight >= 4800) {
      return file
    }
    const { image } = await loadImage(file, {
      orientation: true,
      canvas: true
    })
    const canvas = document.createElement('CANVAS')
    canvas.width = 7200
    canvas.height = 4800
    canvas.getContext('2d').drawImage(image, 0, 0)
    const blob = await new Promise(resolve => canvas.toBlob(resolve, file.type))

    const response = await upload({
      variables: {
        provider: 'gs',
        mimeType: file.type,
        isTemporary: true
      }
    })
    const { uploadUrl, downloadUrl } = _get(response, 'data.upload', {})
    if (uploadUrl) {
      await axios.put(uploadUrl, blob, {
        headers: {
          'Content-Type': file.type,
          'x-goog-acl': 'public-read'
        }
      })
      // setFieldsValue({ frameLandscapeUrl: downloadUrl })
      onChangeFn('frameLandscapeUrl')(downloadUrl)
    }
    // return file
    // if ()
  }

  return <>
    {/* {getFieldDecorator('framePortraitUrl')( */}
    <CloudImageUploader
      placeholderUrl={portraitPreviewUrl}
      fileMiddleware={fileMiddleware}
      onChange={onChangeFn('framePortraitUrl')}
      value={framePortraitUrl}
      loading={loading}
      resize={{
        width: 4800,
        height: 7200
      }}
      accept="image/png"
      isTemporary
    >
      <FormattedMessage id="app.vertical" defaultMessage="Vertical" />
    </CloudImageUploader>
    {/* )} */}
    {/* {getFieldDecorator('frameLandscapeUrl')( */}
    <CloudImageUploader
      placeholderUrl={landscapePreviewUrl}
      fileMiddleware={fileMiddleware}
      onChange={onChangeFn('frameLandscapeUrl')}
      value={frameLandscapeUrl}
      loading={loading}
      resize={{
        width: 7200,
        height: 4800
      }}
      accept="image/png"
      isTemporary
    >
      <FormattedMessage id="app.horizontal" defaultMessage="Horizontal" />
    </CloudImageUploader>
    {/* )} */}
  </>
})

export default FrameImageInput
