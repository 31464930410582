import React, { useState } from 'react'
import { Form, Input, InputNumber, Button, DatePicker, Select, Radio, Spin } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import compose from 'recompose/compose'
import moment from 'moment-timezone'
import EventsSelect from './EventsSelect'
import AdminUserSelect from './AdminUserSelect'
import QbCoupon from './QbCoupon'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 10,
    },
  },
}
const dateFormat = 'YYYY/MM/DD'

const CouponTypeForm = compose(injectIntl)(({ defaultValues, onSubmit, intl }) => {
  const [form] = Form.useForm()
  const { getFieldValue, resetFields } = form
  const {
    _id,
    discount,
    discountType: _discountType,
    eventIds = [],
    limit,
    minItems,
    maxItems,
    name,
    validityPeriod: { start, end } = {},
  } = defaultValues?.couponCodeType ?? {}
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = async (values) => {
    setLoading(true)
    const {
      discountType,
      discount,
      eventIds,
      limit,
      maxItems,
      minItems,
      name,
      organizerIds,
      date: [start, end],
    } = values
    try {
      await onSubmit({
        record: {
          name,
          eventIds,
          minItems,
          maxItems,
          discountType,
          discount,
          limit,
          validityPeriod: {
            start: start.startOf('day'),
            end: end.endOf('day'),
          },
        },
        organizerIds,
      })
      resetFields()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }
  const handleRequiredMessage = ({ id, msg }) =>
    `${intl.formatMessage(
      { id: 'app.fieldRequired', defaultMessage: '{field} is required' },
      { field: intl.formatMessage({ id: `app.${id}`, defaultMessage: msg }) }
    )}`

  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  return (
    <Form form={form} onFinish={handleFormSubmit}>
      <Form.Item
        {...formItemLayout}
        name={['name']}
        label={<FormattedMessage id='app.couponType' defaultMessage='Coupon type' />}
        initialValue={name}
        rules={[{ required: true, message: handleRequiredMessage({ id: 'couponType', msg: 'Coupon type' }) }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['date']}
        label={<FormattedMessage id='app.date' defaultMessage='Date' />}
        initialValue={start && end && [moment(start), moment(end)]}
        rules={[{ type: 'array', required: true, message: handleRequiredMessage({ id: 'date', msg: 'Date' }) }]}
      >
        <DatePicker.RangePicker format={dateFormat} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() =>
          getFieldValue(['date']) && (
            <Form.Item {...formItemLayout} name={['event']} label={<FormattedMessage id='app.event' defaultMessage='Event' />} initialValue={false}>
              <Radio.Group>
                <Radio value={false}>
                  <FormattedMessage id='app.allEvents' defaultMessage='All Events' />
                </Radio>
                <Radio value={true}>
                  <FormattedMessage id='app.selectEvents' defaultMessage='Select Events' />
                </Radio>
              </Radio.Group>
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() =>
          getFieldValue(['event']) && (
            <Form.Item
              {...formItemLayout}
              name={['isPartner']}
              label={<FormattedMessage id='app.isPartner' defaultMessage='Is Partner' />}
              initialValue={'all'}
            >
              <Radio.Group buttonStyle='solid'>
                <Radio.Button value={'all'}>
                  <FormattedMessage id='app.allEvents' defaultMessage='All Events' />
                </Radio.Button>
                <Radio.Button value={'organizer'}>
                  <FormattedMessage id='app.partnerEvent' defaultMessage='Partner Event' />
                </Radio.Button>
                <Radio.Button value={'nonPartner'}>
                  <FormattedMessage id='app.nonPartnerEvent' defaultMessage='Non-Partner Event' />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() =>
          getFieldValue(['isPartner']) === 'organizer' && (
            <Form.Item
              {...formItemLayout}
              name={['organizerIds']}
              label={<FormattedMessage id='app.partner' defaultMessage='Partner' />}
              initialValue={[]}
            >
              <AdminUserSelect mode='multiple' selectAll role={'organizer'} />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() =>
          getFieldValue(['event']) && (
            <Form.Item
              {...formItemLayout}
              name={['eventIds']}
              label={<FormattedMessage id='app.eventLists' defaultMessage='Event Lists' />}
              initialValue={eventIds}
            >
              <EventsSelect
                mode='multiple'
                variables={{
                  organizers: getFieldValue(['organizerIds']),
                  officialPartner: getFieldValue(['isPartner']) === 'all' ? undefined : getFieldValue(['isPartner']) === 'organizer' ? true : false,
                  photoFileDeletedAt: moment(getFieldValue(['date']).at(0))
                    .startOf('day')
                    .toISOString(),
                }}
                selectAll
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['discountType']}
        label={<FormattedMessage id='app.discountType' defaultMessage='Discount type' />}
        initialValue={_discountType}
        rules={[{ required: true, message: handleRequiredMessage({ id: 'discountType', msg: 'Discount type' }) }]}
      >
        <Select placeholder={<FormattedMessage id='app.pleaseSelect' defaultMessage='Please Select' />} style={{ width: '50%' }}>
          <Select.Option value='percentage'>Percentage</Select.Option>
          <Select.Option value='flat'>Flat</Select.Option>
          {/* <Select.Option value='lowest'>Lowest</Select.Option> */}
        </Select>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item
            {...formItemLayout}
            name={['discount']}
            label={<FormattedMessage id='app.discount' defaultMessage='Discount' />}
            initialValue={discount}
            rules={[{ required: true, message: handleRequiredMessage({ id: 'discount', msg: 'Discount' }) }]}
          >
            <InputNumber min={0} max={getFieldValue(['discountType']) === 'percentage' ? 100 : undefined} />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() =>
          getFieldValue(['discountType']) === 'percentage' && (
            <Form.Item {...formItemLayout} name={['limit']} label={<FormattedMessage id='app.limit' defaultMessage='Limit' />} initialValue={limit}>
              <InputNumber min={1} />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item {...formItemLayout} style={{ marginBottom: 0 }} label={<FormattedMessage id='app.photoItems' defaultMessage='Photo items' />}>
        <Form.Item name={['minItems']} initialValue={minItems} style={{ display: 'inline-block', width: '6rem' }}>
          <InputNumber min={1} placeholder='Minimum' />
        </Form.Item>
        <span>- </span>
        <Form.Item name={['maxItems']} initialValue={maxItems} style={{ display: 'inline-block', width: '6rem' }}>
          <InputNumber min={1} placeholder='Maximum' />
        </Form.Item>
      </Form.Item>
      {_id && (
        <Form.Item {...formItemLayout} label={<FormattedMessage id='app.quickbooksCoupon' defaultMessage='Quickbooks Coupon' />}>
          <QbCoupon couponCodeTypeId={_id} />
        </Form.Item>
      )}
      <Form.Item {...tailFormItemLayout}>
        <Button type='primary' htmlType='submit'>
          <FormattedMessage id='app.submit' defaultMessage='Submit' />
        </Button>
      </Form.Item>
    </Form>
  )
})

export default CouponTypeForm
