import React from 'react'
import { Link } from 'react-router-dom'
import { message, Form, Input, Button } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
// import jsonwebtoken from 'jsonwebtoken'
import authAdminMutation from '~/graphql/mutations/authAdminUser.gql'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { useMutation } from '@apollo/client'
import config from './config'

const Login = compose(
  inject('user'),
  observer,
  injectIntl
)(({ history, intl, user }) => {
  const [form] = Form.useForm()
  const [authUser] = useMutation(authAdminMutation)
  sessionStorage.setItem('noticeModal', 'unseen')
  const handleSubmit = async (values) => {
    try {
      const {
        data: {
          authAdminUser: { token },
        },
      } = await authUser({
        variables: values,
      })
      user.login({ token })
      history.push('/')
    } catch (err) {
      message.error(err.message)
    }
  }
  // TODO: if login notify
  return (
    <Form form={form} onFinish={handleSubmit} className='login-form' style={{ width: '200px', margin: 'auto', marginTop: '2em' }}>
      <Form.Item>
        <img src={config.logo} style={{ margin: 'auto', display: 'block' }} alt="Thai.run" />
      </Form.Item>
      <Form.Item name={['username']} rules={[{ required: true, message: 'Please input your username!' }]}>
        <Input
          prefix={<UserOutlined style={{ fontSize: 13 }} />}
          placeholder={intl.formatMessage({ id: 'app.username', defaultMessage: 'Username' })}
        />
      </Form.Item>
      <Form.Item
        name={['password']}
        rules={[{ required: true, message: 'Please input your Password!' }]}
        extra={
          <div style={{ textAlign: 'right' }}>
            <Link to='/forgotpassword' style={{ color: '#c0c0c0' }}>
              Forgot Password?
            </Link>
          </div>
        }
      >
        <Input
          prefix={<LockOutlined style={{ fontSize: 13 }} />}
          type='password'
          placeholder={intl.formatMessage({ id: 'app.password', defaultMessage: 'Password' })}
        />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit' className='login-form-button' style={{ width: '100%', marginBottom: '6px' }}>
          Log in
        </Button>
        <div style={{ textAlign: 'center' }}>
          <br />
          <span>Don't have an account yet?</span>
        </div>
        <Button style={{ width: '100%' }}>
          <Link to='/register'> Register </Link>
        </Button>
      </Form.Item>
    </Form>
  )
})

export default Login
