import React from 'react'
import { FormattedMessage } from 'react-intl'
import { inject, observer } from 'mobx-react'
import { useMutation } from '@apollo/client'
import { compose } from 'recompose'
import { Button, Input, message, Form } from 'antd'
import announcementQuery from '~/graphql/queries/announcement.gql'
import announcementsQuery from '~/graphql/queries/announcements.gql'
import addAnnouncementMutation from '~/graphql/mutations/addAnnouncement.gql'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
}

const AnnouncementCreate = compose(
  inject('user'),
  observer,
  inject('router')
)(({ user, router }) => {
  const [form] = Form.useForm()
  const { resetFields } = form
  const [addAnnouncement] = useMutation(addAnnouncementMutation, {
    refetchQueries: [
      {
        query: announcementQuery,
      },
      {
        query: announcementsQuery,
      },
    ],
  })
  const handleFormSubmit = async (values) => {
    const { announcementTitle, announcementMessage } = values
    try {
      const {
        data: {
          newAnnouncement: {
            record: { title },
          },
        },
      } = await addAnnouncement({
        variables: {
          record: {
            title: announcementTitle,
            message: announcementMessage,
          },
        },
      })
      message.info(`Announcement "${title}" created`, 5)
      resetFields()
      router.push('/announcements')
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <>
      {['superadmin', 'admin', 'accounting'].includes(user.role) && (
        <Form form={form} onFinish={handleFormSubmit} style={{ paddingTop: '20px' }}>
          <Input.Group>
            <Form.Item
              {...formItemLayout}
              name={['announcementTitle']}
              label={<FormattedMessage id='app.announcementTitle' defaultMessage='Title' />}
              initialValue={''}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name={['announcementMessage']}
              label={<FormattedMessage id='app.announcementMessage' defaultMessage='Message' />}
              initialValue={''}
            >
              <Input.TextArea autoSize={{ minRows: 3 }} />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type='primary' htmlType='submit'>
                <FormattedMessage id='app.save' defaultMessage='Save' />
              </Button>
            </Form.Item>
          </Input.Group>
        </Form>
      )}
    </>
  )
})

export default AnnouncementCreate
