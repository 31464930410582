import React from 'react'
import { Button } from 'antd'
import getPhotoDownloadUrlQuery from '~/graphql/queries/getPhotoDownloadUrl.gql'
import { useApolloClient } from '@apollo/client'
import UserPermission from '~/components/UserPermission'
import _get from 'lodash/get'

const DownloadPhoto = ({ _id, allowed }) => {
  const client = useApolloClient()
  const handleDownload = () => {
    client.query({
      query: getPhotoDownloadUrlQuery,
      variables: {
        _id: _id
      }
    }).then(response => {
      let downloadUrl = _get(response, 'data.getPhotoDownloadUrl.url')
      window.location.href = downloadUrl
      // window.open(downloadUrl)
    }).catch(error => {
      console.error(error)
    })
  }

  if (!allowed) {
    return null
  }

  return (<UserPermission grantedTo={['superadmin', 'admin', 'accounting']} >
    <Button onClick={handleDownload}>
      Download
    </Button>
  </UserPermission>)
}

export default DownloadPhoto
