import React, { useMemo } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Button, Result, Alert } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { compose } from 'recompose'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import FineUploaderS3 from 'fine-uploader-wrappers/s3'
import _get from 'lodash/get'
import sha256 from 'hash.js/lib/hash/sha/256'
import './PhotoUpload.css'
import currentAdminUserCreditsQuery from '~/graphql/queries/currentAdminUserCredits.gql'
import processDuplicatePhotoMutation from '~/graphql/mutations/processDuplicatePhoto.gql'
import ThairunUploader from './ThairunUploader'
import moment from 'moment-timezone'

const ALLOWED_MIME = 'image/jpeg'
const ALLOWED_EXTENSIONS = ['jpg', 'jpeg']


const PhotoUploadTools = compose(injectIntl)(({ intl, event }) => {
  const { error, loading, data } = useQuery(currentAdminUserCreditsQuery)
  const creditsAvailable = _get(data, 'currentAdminUser.creditBalance.total')
  const client = useApolloClient()
  
  const IMAGE_DIMENSION = {
    small: 1800,
    medium: 2400,
    large: 3000,
    xlarge: 3600,
    xxlarge: 4800,
  }
  const smallestSizeLabel = event?.userUpload?.photoSizes?.reduce((prev, label) => ((IMAGE_DIMENSION[label] < IMAGE_DIMENSION[prev]) ? label : prev), 'xxlarge')

  const uploader = useMemo(() => new FineUploaderS3({
    options: {
      maxConnections: 10,
      resume: {
        // enabled: true
        preventRetryResponseProperty: 'stop'
      },
      chunking: {
        enabled: false
        // concurrent: {
        //   enabled: true
        // }
        // requires success.endpoint, e.g. REACT_APP_UPLOAD_SUCCESS_URI
      },
      cors: {
        expected: true
      },
      request: {
        endpoint: `https://storage.googleapis.com/${process.env.REACT_APP_UPLOAD_BUCKET}`,
        accessKey: process.env.REACT_APP_UPLOAD_ACCESS_KEY
      },
      signature: {
        customHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        // version: 4,
        endpoint: process.env.REACT_APP_UPLOAD_SIGNATURE_URI
      },
      objectProperties: {
        // acl: 'private',
        bucket: process.env.REACT_APP_UPLOAD_BUCKET
      },
      uploadSuccess: {
        customHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        endpoint: process.env.REACT_APP_UPLOAD_SUCCESS_URI
      },
      retry: {
        enableAuto: false
        // maxAutoAttempts: 5
      },
      validation: {
        ...(creditsAvailable !== undefined && { itemLimit: creditsAvailable }),
        acceptFiles: ALLOWED_MIME,
        allowedExtensions: ALLOWED_EXTENSIONS,
        stopOnFirstInvalidFile: false,
        ...(event.photoDownloadMode !== 'free' && {
          image: {
            minHeight: IMAGE_DIMENSION[smallestSizeLabel],
            minWidth: IMAGE_DIMENSION[smallestSizeLabel]
          }
        })
      },
      messages: {
        tooManyItemsError: intl.formatMessage({ id: 'app.messages.fineuploader.tooManyItemsError', defaultMessage: `You try to upload \\{netItems\\} photos, but you only have \\{itemLimit\\} credits available` }),
        typeError: intl.formatMessage({ id: 'app.messages.fineuploader.typeError', defaultMessage: `\\{file\\} has an invalid extension. Valid extension(s): \\{extensions\\}` })
      },
      callbacks: {
        // onValidateBatch: async (files) => {
        //   console.log({ files })
        //   const response = await client.query({ query: currentAdminUserCreditsQuery })
        //   console.log({ response })
        //   return false
        // },
        // TODO: use onValidate instead?
        onUpload: async (fileId) => {
          const file = uploader.methods.getFile(fileId)
          const hash = await new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.onload = (e) => { // file has been read successfully
              const buffer = e.target.result
              const message = new Uint8Array(buffer) // the data to be digested
              // console.log({ message })
              const hash = sha256().update(message).digest('hex')
              resolve(hash)
            }
            fileReader.readAsArrayBuffer(file)
          })
          const response = await client.mutate({
            mutation: processDuplicatePhotoMutation,
            variables: { hash, eventId: event._id }
          }) // handle duplicate photo
          const isDuplicate = _get(response, 'data.processDuplicatePhoto')
          if (isDuplicate) {
            // TODO: show messages in ui
            console.warn(`duplicate ${file.name}`)
            return uploader.methods.cancel(fileId)
          }

          uploader.methods.setUploadSuccessParams({
            eventId: event._id,
            // featured: form.getFieldValue('featured'),
            hash
          }, fileId)
        }
      }
    }
  }), [creditsAvailable]) // eslint-disable-line react-hooks/exhaustive-deps
  // }))

  const { userUpload } = event
  if (error) {
    return 'Error'
  }
  if (loading) {
    return 'Loading...'
  }
  if (creditsAvailable <= 0) {
    return <Result
      status="warning"
      title={<FormattedMessage id="app.notice.insufficientCredits" defaultMessage="Insufficient credits available" />}
      subTitle={<FormattedMessage id="app.notice.insufficientCreditsLeft" defaultMessage="You have {creditAmount} available, add more credits to continue uploading" values={{ creditAmount: <FormattedNumber value={creditsAvailable}/> }} />}
      extra={<React.Fragment>
        <Link to={`/events`}>
          <Button icon={<LeftOutlined />}><FormattedMessage id="app.allEvents" defaultMessage="All Events" /></Button>
        </Link>
        <Link to="/credits/buy">
          <Button type="primary"><FormattedMessage id="app.buyCredits" defaultMessage="Buy Credits" /></Button>
        </Link>
      </React.Fragment>}
    />
  }
  return <React.Fragment>
    {event.photoDownloadMode !== 'free' && <div style={{ textAlign: 'center', padding: 6 }}>
      <FormattedMessage id="app.uploadPhotoSizes" defaultMessage="Photo sizes to sell" />: {userUpload.photoSizes.join(', ')}
    </div>}
    {moment().isAfter('2020-01-01') &&
      <Alert
        showIcon
        message={<FormattedMessage id="app.notice.creditsLeftForUpload" defaultMessage="You have {creditAmount} available, you can upload {creditAmount} photos" values={{ creditAmount: <FormattedNumber value={creditsAvailable}/> }} />}
      />
    }
    {event.uploadable
      ? <ThairunUploader uploader={uploader} />
      : <div style={{ textAlign: 'center', margin: '50px 0px' }}>
        <FormattedMessage
          id="app.uploadableAfter"
          defaultMessage="You can upload photos after {date}"
          values={{ date: moment(event.uploadableAt).format('DD/MM/YYYY HH:mm') }}
        />
      </div>
    }
  </React.Fragment>
})

export default PhotoUploadTools
