import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import qbFindVendorsQuery from '~/graphql/queries/qbFindVendors.gql'
import qbEventVendorQuery from '~/graphql/queries/qbEventVendor.gql'
import qbSetEventVendorMutation from '~/graphql/mutations/qbSetEventVendor.gql'
import { Select } from 'antd'
const QbVendor = ({ eventId }) => {
  const [qbSetEventVendor] = useMutation(qbSetEventVendorMutation)
  const { error, loading, data } = useQuery(qbFindVendorsQuery)
  const { data: qbEventVendorData } = useQuery(qbEventVendorQuery, {
    variables: {
      eventId
    }
  })
  if (error) {
    return 'Error'
  }
  if (loading) {
    return 'Loading...'
  }
  // console.log({ eventId, qbEventVendor })
  const value = qbEventVendorData?.qbEventVendor?.qbId
  // console.log({ qbEventVendor })
  // const name = qbEventVendor?.qbName
  const handleChange = async (qbId) => {
    console.log('change', qbId)
    const Vendor = data?.qbFindVendors.find(c => c.value === qbId)
    const qbName = Vendor?.name
    await qbSetEventVendor({ variables: {
      eventId,
      qbId,
      qbName
    }})
  }
  return <Select
    showSearch
    onChange={handleChange}
    defaultValue={value}
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    style={{ width: '100%' }}
  >
    {data?.qbFindVendors.map(c =>
      <Select.Option key={c.value} value={c.value} >{c.name}</Select.Option>
    )}
  </Select>
}

export default QbVendor
