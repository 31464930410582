import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { graphql } from '@apollo/client/react/hoc'
import {
  Button, Select, message, Spin, Row, Col
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _head from 'lodash/head'
import _difference from 'lodash/difference'
import { FormattedMessage } from 'react-intl'
// import _find from 'lodash/find';
import _map from 'lodash/map'
import _findIndex from 'lodash/findIndex'
import PhotoTagNav from '~/components/PhotoTag/PhotoTagNav'

import photoTripletQuery from '~/graphql/queries/photoTriplet.gql'
import updatePhotoTagsQuery from '../graphql/mutations/updatePhotoTags.gql'

@graphql(photoTripletQuery, {
  options: props => ({
    variables: {
      _id: props.match.params.photoId
    },
    fetchPolicy: 'network-only'
  })
})
@graphql(updatePhotoTagsQuery, { name: 'updatePhotoTags' })
class PhotoTag extends Component {
  state = {
    drawing: false,
    tagging: false,
    save: '',
    tag: {
      position: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      },
      label: ''
    },
    tags: [],
    saving: false
  };

  mouse = {
    x: 0,
    y: 0,
    startX: 0,
    startY: 0
  };

  componentWillReceiveProps (nextProps) {
    if (nextProps.data.loading) {
      this.setState({ tags: [] })
    } else {
      const tags = _get(nextProps, 'data.photoTriplet.current.tags', []) ?? []
      const oldTags = tags.map(tag =>
        _omit(tag, ['__typename', 'position.__typename'])
      )
      this.setState({ tags: oldTags })
    }
  }

  saveTag = () => {
    this.setState({ saving: !this.state.saving })
    this.props
      .updatePhotoTags({
        variables: {
          id: this.props.match.params.photoId,
          tags: this.state.tags
        }
      })
      .then(() => {
        this.setState({ saving: !this.state.saving })
        message.info(`Successfully update photo tags.`)
      })
      .catch(err => {
        this.setState({ saving: !this.state.saving })
        const error = _get(
          _head(err.graphQLErrors),
          'message',
          'Failed to tag photo'
        )
        message.error(error)
      })
    // console.log(this.state.tags);
    // console.log('object');
  };

  handleNavigation = (path) => {
    this.props.history.push(path)
  }

  // {photo && tags.length ? (
  //   <div className="tag-description">
  //     <Select
  //       mode="tags"
  //       style={{ width: "100%" }}
  //       onChange={(value) => addTagByTyping(value)}
  //       value={tags.map(tag => tag.label)}
  //       tokenSeparators={[","]}
  //     >
  //       {tags.map(tag => (
  //         <Option key={tag.label} value={tag.label}>
  //           {tag.label}
  //         </Option>
  //       ))}
  //     </Select>
  //     {/* {tags.map(tag => {
  //       return (
  //         <a className="tag-name" key={tag.label}>
  //           {tag.label}
  //           <CloseOutlined onClick={() => onRemoveTag(tag)} />
  //         </a>
  //       );
  //     })} */}
  //   </div>
  // ) : null}

  render () {
    function Tag () {
      this.position = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      }
      this.label = ''
    }

    const addTagByTyping = value => {
      const diffBySelect = _difference(value, _map(this.state.tags, 'label'))
      if (diffBySelect.length !== 0) {
        const newTag = {
          label: diffBySelect[0]
        }
        this.setState({ tags: [...this.state.tags, newTag] })
        this.setState({ tag: new Tag() })
        this.setState({ tagging: false })
        this.refs.taginput.value = ''
        this.setState({ save: '' })
      } else {
        const diffByState = _difference(_map(this.state.tags, 'label'), value)
        const tagIndex = _findIndex(this.state.tags, ['label', ...diffByState])
        const tags = [
          ...this.state.tags.slice(0, tagIndex),
          ...this.state.tags.slice(tagIndex + 1)
        ]
        this.setState({ tags })
      }
    }

    const setMousePosition = event => {
      event = event || window.event
      let target = event.target || event.srcElement
      let rect = target.getBoundingClientRect()
      this.mouse.x = event.clientX - rect.left
      this.mouse.y = event.clientY - rect.top
    }

    const onMouseDown = event => {
      if (event.target.id === 'photo') {
        this.setState({ drawing: true, tagging: false })
        setMousePosition(event)
        this.mouse.startX = this.mouse.x
        this.mouse.startY = this.mouse.y
        if (!this.state.tag.label) {
          this.setState({ tag: new Tag() })
        }
      }
    }

    const onMouseMove = event => {
      setMousePosition(event)
      if (this.state.drawing) {
        this.setState({
          tag: {
            position: {
              top:
                this.mouse.y < this.mouse.startY
                  ? this.mouse.y
                  : this.mouse.startY,
              left:
                this.mouse.x < this.mouse.startX
                  ? this.mouse.x
                  : this.mouse.startX,
              bottom:
                this.mouse.y > this.mouse.startY
                  ? this.mouse.y
                  : this.mouse.startY,
              right:
                this.mouse.x > this.mouse.startX
                  ? this.mouse.x
                  : this.mouse.startX
            },
            label: ''
          }
        })
      }
    }

    const onMouseUp = event => {
      // this.refs.taginput.focus();
      this.setState({ drawing: false })
      let width = this.state.tag.position.bottom - this.state.tag.position.top
      let height = this.state.tag.position.right - this.state.tag.position.left
      if (width < 25 || height < 25) {
        this.setState({ tag: new Tag(), tagging: false })
      } else {
        this.refs.taginput.focus()
        // ReactDOM.findDOMNode(this.refs.taginput).focus();
        let taginput = ReactDOM.findDOMNode(this.refs.taginput)
        setTimeout(function () {
          taginput.focus()
        }, 10)
        this.setState({ tagging: true })
        // this.refs.taginput.focus();
        // taginput.focus();
      }
      // this.refs.taginput.focus();
    }

    const onKeyUp = event => {
      if (event.keyCode === 13) {
        if (this.refs.taginput.value) {
          let label = this.refs.taginput.value
          let newTag = this.state.tag
          newTag.label = label
          this.setState({ tags: [...this.state.tags, newTag] })
          this.setState({ tag: new Tag() })
          this.setState({ tagging: false })
          this.refs.taginput.value = ''
          this.setState({ save: '' })
        }
      }
    }

    // const onRemoveTag = tagObject => {
    //   const tagIndex = _findIndex(this.state.tags, tagObject);
    //   const tags = [
    //     ...this.state.tags.slice(0, tagIndex),
    //     ...this.state.tags.slice(tagIndex + 1)
    //   ];
    //   this.setState({ tags });
    // };

    const { loading } = this.props.data
    if (loading) {
      return <div>Loading...</div>
    } else {
      const { tags } = this.state
      const currentPhoto = _get(this.props, 'data.photoTriplet.current', null)
      const previousPhoto = _get(this.props, 'data.photoTriplet.previous', null)
      const nextPhoto = _get(this.props, 'data.photoTriplet.next', null)
      return (
        <div>
          <Spin spinning={this.state.saving}>
            <div className="photo-viewer">

              <Row type="flex" justify="center" align="middle" style={{ marginBottom: '10px' }} >
                <Col span={6}>
                  <Select mode="tags" style={{ width: '300px' }}
                    onChange={(value) => addTagByTyping(value)}
                    value={tags.map(tag => tag.label)}
                    tokenSeparators={[',']}
                  >
                    {tags.map((tag, index) => (
                      <Select.Option key={'option' + tag.label + index} value={tag.label} >
                        {tag.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col span={1} offset={1}>
                  <Button type="default"
                    icon={<SaveOutlined />} size="default"
                    onClick={this.saveTag}
                  >
                    <FormattedMessage id="app.save" defaultMessage="Save"/>
                  </Button>
                </Col>
              </Row>

              {/* <div className="tag-description">
                  <Select
                    mode="tags"
                    style={{ width: "300px", height: "30px" }}
                    onChange={(value) => addTagByTyping(value)}
                    value={tags.map(tag => tag.label)}
                    tokenSeparators={[","]}
                  >
                    {tags.map(tag => (
                      <Option key={tag.label} value={tag.label}>
                        {tag.label}
                      </Option>
                    ))}
                  </Select>
                  <div className="tag-tools">
                    <Button
                        type="default"
                        icon={<SaveOutlined />}
                        size="normal"
                        onClick={this.saveTag}
                    >
                      Save
                    </Button>
                  </div>
                  {tags.map(tag => {
                    return (
                      <a className="tag-name" key={tag.label}>
                        {tag.label}
                        <CloseOutlined onClick={() => onRemoveTag(tag)} />
                      </a>
                    );
                  })}
                </div>
               */}
              {(() => {
                if (currentPhoto) {
                  return (
                    <div>
                      <PhotoTagNav currentPhoto={currentPhoto}
                        previousPhoto={previousPhoto}
                        nextPhoto={nextPhoto}
                        onNavigate={this.handleNavigation}
                      />
                      <div
                        id="canvas"
                        onMouseDown={onMouseDown}
                        onMouseMove={onMouseMove}
                        onMouseUp={onMouseUp}
                        className={this.state.drawing ? 'drawing' : ''}
                        key="canvas"
                      >
                        {(() => {
                          // rectangle
                          let idle =
                            this.state.tag.position.top ===
                              this.state.tag.position.bottom ||
                            this.state.tag.position.left ===
                              this.state.tag.position.right
                          return (
                            <div
                              className={
                                'rectangle ' +
                                (this.state.drawing ? 'drawing ' : '') +
                                (idle ? 'idle ' : '')
                              }
                              style={{
                                top: this.state.tag.position.top,
                                left: this.state.tag.position.left,
                                width:
                                  this.state.tag.position.right -
                                  this.state.tag.position.left,
                                height:
                                  this.state.tag.position.bottom -
                                  this.state.tag.position.top
                              }}
                            />
                          )
                        })()}
                        {(() => {
                          // rectangles
                          return tags.map((tag, index) => {
                            const top = _get(tag, 'position.top', 0)
                            const bottom = _get(tag, 'position.bottom', 0)
                            const left = _get(tag, 'position.left', 0)
                            const right = _get(tag, 'position.right', 0)
                            return (
                              <div
                                className="rectangle"
                                key={'div' + tag.label + index}
                                style={{
                                  top: top,
                                  left: left,
                                  width: right - left,
                                  height: bottom - top
                                }}
                                data-tag={tag.label}
                              />
                            )
                          })
                        })()}
                        {(() => {
                          // tagger
                          let halfX =
                            this.state.tag.position.left +
                            (this.state.tag.position.right -
                              this.state.tag.position.left) /
                              2
                          return (
                            <div
                              id="tagger"
                              className={this.state.tagging ? 'tagging' : ''}
                              style={{
                                top: this.state.tag.position.bottom,
                                left: halfX - 100
                              } // half of tagger's width
                              }
                            >
                              <div className="ui pointing label">
                                <div className="ui input">
                                  <input
                                    id="taginput"
                                    type="text"
                                    ref="taginput"
                                    placeholder="Tag..."
                                    onKeyUp={onKeyUp}
                                    onFocus={() => {}}
                                    tabIndex="0"
                                    autoFocus
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        })()}
                        <img
                          id="photo"
                          src={_get(currentPhoto, 'view.preview.url', '')}
                          alt=""
                          draggable="false"
                          ref="image"
                        />
                      </div>
                    </div>
                  )
                }
              })()}
            </div>
          </Spin>
        </div>
      )
    }
  }
}

export default PhotoTag
