import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { List, Affix, Typography, Pagination, Input, DatePicker, Tag, Modal, Tooltip} from 'antd'
import { PictureOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { useMediaQuery } from 'react-responsive'

import orderListQuery from '~/graphql/queries/orderList.gql'
import EventSelect from '~/components/EventSelect'
import PaymentMethod from '~/components/PaymentMethod'
import OrderDetail from '../components/OrderDetail'
import AdminUserSelect from '~/components/AdminUserSelect'

const paymentMethodMap = {
  undefined: 'รหัสอ้างอิง',
  all: 'รหัสอ้างอิง',
  omise_card: 'เลขบัตร 4 ตัวสุดท้าย',
  omise_truemoney: 'เบอร์โทรศัพท์',
  // omise_netbank: '',
  linepay_web: 'transaction id',
  scb_qr: 'reference 2',
}

const Orders = compose(
  inject('router'), // TODO: remove mobx dependency, sync router with context or read from url directly
  observer,
  inject('user')
)(({ user, router }) => {

  const { location } = router
  const {
    s: search,
    p: page,
    e: eventId,
    from: startDate,
    to: endDate,
    m: method,
    ref: references,
    // ph: clientPhone,
    pho: photographerId,
    o: orderId
    // pic: photoId,
  } = location.query
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const intl = useIntl()

  const { loading, error, data } = useQuery(orderListQuery, {
    variables: {
      eventId,
      photographerId,
      search,
      method,
      references,
      completedAtStart: moment(startDate).toISOString(),
      completedAtEnd: moment(endDate).endOf('day').toISOString(),
      page: parseInt(page, 10) || 1,
      // eventId,
      // photographerId,
      // status,
      // clientSearch,
      // method,
      // completedAtStart,
      // completedAtEnd,
      // references,
      // page
    }
  })
  if (error) {
    console.error(error)
  }

  useEffect(() => {
    // if (sessionStorage.getItem('noticeModal') === 'unseen') {
    //   setVisible(true)
    // }
    if (!location.query.from && !location.query.to) {
      location.query = {
        ...location.query,
        from: moment().subtract(1, 'week').format(moment.HTML5_FMT.DATE),
        to: moment().format(moment.HTML5_FMT.DATE),
      }
      // setFieldsValue({ date: [moment().subtract(1, 'week'), moment()], email, event, method })
      // setEventsSearch()
    }
  }, [location.query.from, location.query.to, location.query])
  const { currentPage, itemCount, perPage } = data?.orderList?.pageInfo ?? {}


  // const paymentMethodMap = (method) => {
  //   all: '',
  //   omise_card: 'Credit/Debit Card',
  //   omise_truemoney: 'Truemoney',
  //   // omise_netbank: '',
  //   linepay_web: 'Linepay',
  //   scb_qr: 'QR Code',
  // }
  // console.log({ data }, perPage)
  const handlePageChange = (page) => {
    location.query = {
      ...location.query,
      p: page,
    }
  }

  const handleCompletedDateRange = ([$startDate, $endDate]) => {
    location.query = {
      ...location.query,
      ...(($startDate && $endDate) && {
        from: $startDate.format(moment.HTML5_FMT.DATE),
        to: $endDate.format(moment.HTML5_FMT.DATE)
      })
    }
  }
  const handleReferenceChange = (references) => {
    const { ref: _references, ...query } = location.query
    location.query = {
      ...query,
      ...(references && { ref: references })
    }
  }

  const handleSearchChange = (search) => {
    const { s: _search, ...query } = location.query
    location.query = {
      ...query,
      ...(search && { s: search })
    }
  }

  // const handleMethodName = (method) => {
  //   switch (method) {
  //     case 'all':
  //       return ''
  //     case 'omise_card':
  //       return 'Credit/Debit Card'
  //     case 'omise_truemoney':
  //       return 'Truemoney'
  //     case 'linepay_web':
  //       return 'Linepay'
  //     case 'scb_qr':
  //       return 'QR Code'
  //   }
  // }

  const handlePhotographerChange = (photographerId) => {
    const { pho: _photographerId, ...query } = location.query
    location.query = {
      ...query,
      ...((photographerId && photographerId !== 'all') && { pho: photographerId })
    }
  }

  const handleMethodChange = (method) => {
    const { m: _method, ...query } = location.query
    location.query = {
      ...query,
      ...(method && { m: method })
    }
  }
  const handleOrderDetail = (orderId) => {
    const { o: _orderId, ...query } = location.query
    location.query = {
      ...query,
      ...(orderId && { o: orderId })
    }
  }

  const handleEventChange = (eventId) => {
    const { e: _eventId, ...query } = location.query
    location.query = {
      ...query,
      ...(eventId && { e: eventId })
    }
  }
  // if (!perPage) {
  //   return 'loading...'
  // }

  return <>
    <List
      loading={loading}
      header={
        <Affix offsetTop={0}>
          <div style={{ textAlign: 'center', background: 'white', padding: '.5em 0' }}>
            <Input.Group compact={!isMobile}>
              <EventSelect value={eventId} onChange={handleEventChange}
                style={isMobile
                  ? { width: '100%' }
                  : { width: 260 }
                }
              />{/*TODO: use acl system */}
              <DatePicker.RangePicker
                style={isMobile
                  ? { width: '100%', marginTop: '.5em' }
                  : { width: 260 }
                }
                format="ll"
                timePicker={false}
                // ranges={{ 'last month': [moment().subtract(1, 'month'), moment()] }}
                value={(startDate && endDate) ? [moment(startDate), moment(endDate)] : []}
                defaultValue={(startDate && endDate) ? [moment(startDate), moment(endDate)] : []}
                onChange={handleCompletedDateRange}
                />
              {/* <Button type='primary' icon={<SearchOutlined />} /> */}
            </Input.Group>
            <Input.Group compact>
              {/* photographer select, email, method, search */}
            </Input.Group>
          </div>
          {['admin', 'superadmin', 'customerService', 'accounting'].includes(user.role) &&  <div style={{ textAlign: 'center', background: 'white', padding: '.5em 0' }}>
            <Input.Group compact={!isMobile}>
              <Input.Search 
                allowClear
              // value={email} 
                onSearch={handleSearchChange}
              // onChange={handleSearchChange}
                style={isMobile
                    ? { width: '100%', marginTop: '.5em' }
                    : { width: 260 }
                  } placeholder={intl.formatMessage({ id: 'app.emailOrOrderCode', defaultMessage: 'Email or Order Code' })}
              />
              <PaymentMethod value={method} onChange={handleMethodChange} style={isMobile
                    ? { width: '100%', marginTop: '.5em' }
                    : { width: 260 }
              }/>
              <Input.Search 
                  allowClear
                  style={isMobile
                    ? { width: '100%', marginTop: '.5em' }
                    : { width: 260 }
                  }
                  placeholder={paymentMethodMap[method]}
                  // value={handleMethodName(method)}
                  onSearch={handleReferenceChange}
                />
              <AdminUserSelect 
                  value={photographerId} 
                  onChange={handlePhotographerChange} 
                  role={'photographer'} 
                  selectAll 
                  style={isMobile
                    ? { width: '100%', marginTop: '.5em' }
                    : { width: 260 }
                  } />
            </Input.Group>
          </div>}
        </Affix>
      }
      dataSource={data?.orderList?.items}
      itemLayout="horizontal"
      renderItem={order => (
        <List.Item
          style={{ position: 'relative' }}
          actions={[<>
            {order.hasUnlimitedItems
              ? <Tooltip>&infin; <PictureOutlined /></Tooltip>
              : (['photographer'].includes(user.role) // TODO: handle legacy multi event orders for organizers and brokers
                ? <Tooltip title={`sold ${order.accessibleItemCount} photos of total ${order.itemCount} photos for this order`}>{order.accessibleItemCount} / {order.itemCount} <PictureOutlined /></Tooltip>
                : <Tooltip>{order.itemCount} <PictureOutlined /></Tooltip>
              )
            }
          </>]}
          // extra={}
        >
          <List.Item.Meta title={order.event?.title} description={moment(order.completedAt).format('lll')} />
          {/* <Tag style={{ position: 'absolute', top: '1em', right: 0}}>+1000 THB</Tag> */}
          <div style={{ position: 'absolute', top: '1em', right: 0, textAlign: 'right' }}>
            {['superadmin', 'admin', 'accounting', 'customerService'].includes(user.role)
            ? <Typography.Text strong style={{ color: '#52c41a' }}>
              {/* + {intl.formatNumber(order.currentUserShare?.amount, { style: 'currency', currency: 'THB', minimumFractionDigits: 2, currencyDisplay: 'code' })} */}
              + {intl.formatNumber(order.shareableAmount, { minimumFractionDigits: 2 })} THB
              {/* {record.creditAmount > 0 && '+'}<FormattedNumber value={record.creditAmount} /> */}
            </Typography.Text>
            :<Typography.Text strong style={{ color: '#52c41a' }}>
              {/* + {intl.formatNumber(order.currentUserShare?.amount, { style: 'currency', currency: 'THB', minimumFractionDigits: 2, currencyDisplay: 'code' })} */}
              + {intl.formatNumber(order.currentUserShare?.amount, { minimumFractionDigits: 2 })} THB
              {/* {record.creditAmount > 0 && '+'}<FormattedNumber value={record.creditAmount} /> */}
            </Typography.Text>}
            <br />
            {['superadmin', 'admin', 'accounting', 'customerService'].includes(user.role) && 
              order.couponCodes?.map(coupon => <Tag key={coupon._id} style={{ marginRight: 0, marginLeft: 4 }} color="blue">{coupon.couponCodeType.name}</Tag>)
            }
            {order.photoPackage && 
              <Tag style={{ marginRight: 0, marginLeft: 4  }} color="orange">{order.photoPackage.name}</Tag>
            }
          </div>
          <a onClick={() => handleOrderDetail(order._id)}>{order.code}</a> {['superadmin', 'admin', 'accounting', 'customerService'].includes(user.role) && order.clientEmail}{/* eslint-disable-line jsx-a11y/anchor-is-valid */}
        </List.Item>
      )}
      // pagination={!loading && {
      //   style: { textAlign: 'center' },
      //   simple: true,
      //   current: currentPage,
      //   total: itemCount,
      //   pageSize: perPage,
      //   itemRender: (page, type, $pagination) => <Affix offsetBottom={8}>{$pagination}</Affix>,
      //   onChange: handlePageChange,
      // }}
    />
    
    {(!loading && itemCount > 0) && <Affix offsetBottom={0}>
      <div style={{ textAlign: 'center', background: 'white', padding: '.5em 0' }}>
        <div>{itemCount} items</div>
        <Pagination
          simple
          // showTotal={(total, range) => `${total} ${range}`}
          current={currentPage}
          total={itemCount}
          pageSize={perPage}
          onChange={handlePageChange}
          // itemRender={(page, type, $pagination) => <Affix offsetBottom={8}>{$pagination}</Affix>}
        />
      </div>
    </Affix>}
    {Boolean(orderId) && <Modal open onCancel={() => handleOrderDetail(null)} footer={null}>
      <OrderDetail orderId={orderId} />
    </Modal>}
  </>
})

export default Orders