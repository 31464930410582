import React from 'react'
import EventForm from './EventForm'
import { message } from 'antd'
import { inject } from 'mobx-react'
import { useQuery, useMutation } from '@apollo/client'
import createEventMutation from '~/graphql/mutations/createEvent.gql'
import currentAdminUserQuery from '~/graphql/queries/currentAdminUser.gql'
import compose from 'recompose/compose'

const EventCreate = ({ user, router }) => {
  const { loading, data } = useQuery(currentAdminUserQuery)
  const [createEvent] = useMutation(createEventMutation)
  if (loading) {
    return 'Loading...'
  }
  const handleSubmit = async (values) => {
    // TODO: try catch
    values.record.createdByUserId = user._id
    const { data } = await createEvent({
      variables: values
    })
    message.info(`Event "${data?.createEvent?.record?.title}" created`, 10)
    router.push('/events')
  }
  const { currentAdminUser: createdByUser } = data
  const defaultValues = {
    record: {
      officialPartner: false,
      photoDownloadMode: 'priced',
      hidden: false,
      photoTagFaces: true,
      photoTagText: false,
      approved: null,
      registrationMethod: 'free',
      raceType: 'road',
      shareInterval: 30,
      organizerShareUnit: 'percentage',
      // raceDistance: 100,
      createdByUser
    }
  }
  return <EventForm onSubmit={handleSubmit} defaultValues={defaultValues} />
}

export default compose(
  inject('router'),
  inject('user')
)(EventCreate)
