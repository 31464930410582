import React, { useEffect, useState } from 'react'
import { Form, Input, Button, DatePicker, Switch, InputNumber, Radio, Select, Checkbox, Row, Col, Spin } from 'antd'
import adminUserListQuery from '~/graphql/queries/adminUserList.gql'
import photoPackagesQuery from '~/graphql/queries/photoPackages.gql'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import CloudUploader from '~/components/CloudUploader'
import CloudImageUploader from '~/components/CloudImageUploader'

// import ContractForm from '~/components/ContractForm'
import province from '~/assets/jsons/changwats' // not available on server
import UserPermission from '~/components/UserPermission'
import FrameImageInput from '~/components/FrameImageInput'
import compose from 'recompose/compose'
import { useQuery } from '@apollo/client'
import QbCustomer from './QbCustomer'
import QbVendor from './QbVendor'
import AdminUserSelect from './AdminUserSelect'
import { inject } from 'mobx-react'
import config from '../config'

// const fileReader = new FileReader()

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { offset: 1, span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  style: {
    lineHeight: 3,
  },
}
const formItemLongLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { offset: 1, span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  style: {
    lineHeight: 3,
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 10,
    },
  },
  style: {
    lineHeight: 3,
  },
}

const BrokerDetail = ({ changed, defaultValues, brokerId, form: { setFieldsValue } }) => {
  const { loading, data } = useQuery(adminUserListQuery, {
    variables: {
      _id: brokerId,
      role: 'broker',
    },
  })
  const brokers = data?.adminUserList?.items
  const broker = brokers?.find((item) => item._id === brokerId)
  const { brokerShare, brokerShareUnit } = broker?.brokerData ?? {}
  useEffect(() => {
    if (!loading && changed) {
      setFieldsValue({ record: { brokerShare, brokerShareUnit } })
    }
  }, [changed, loading, brokerShare, brokerShareUnit, setFieldsValue])
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '10vh' }} />
  }
  return (
    <Form.Item
      labelCol={{ sm: { span: 8 } }}
      wrapperCol={{ sm: { span: 16 } }}
      name={['record', 'brokerShare']}
      labelAlign='left'
      label={<FormattedMessage id='app.broker' defaultMessage='Broker' />}
      initialValue={defaultValues?.record?.brokerShare}
      extra={<FormattedMessage id='app.range' defaultMessage='Range {min}-{max}' values={{ min: 0, max: 75 }} />}
      style={{ lineHeight: 3 }}
    >
      <InputNumber min={0} max={75} precision={2} />
    </Form.Item>
  )
}

const OrganizerDetail = ({ changed, defaultValues, organizerId, form: { setFieldsValue } }) => {
  const { loading, data } = useQuery(adminUserListQuery, {
    variables: {
      _id: organizerId,
      role: 'organizer',
    },
  })
  const organizers = data?.adminUserList?.items
  const organizer = organizers?.find((item) => item._id === organizerId)
  const { organizerShare, organizerShareUnit } = organizer?.organizerData ?? {}
  useEffect(() => {
    if (!loading && changed) {
      setFieldsValue({ record: { organizerShare, organizerShareUnit } })
    }
  }, [changed, loading, organizerShare, organizerShareUnit, setFieldsValue])
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '10vh' }} />
  }
  return (
    <Form.Item
      labelCol={{ sm: { span: 8 } }}
      wrapperCol={{ sm: { span: 16 } }}
      name={['record', 'organizerShare']}
      labelAlign='left'
      label={<FormattedMessage id='app.organizer' defaultMessage='Organizer' />}
      initialValue={defaultValues?.record?.organizerShare}
      extra={<FormattedMessage id='app.range' defaultMessage='Range {min}-{max}' values={{ min: 0, max: 75 }} />}
      style={{ lineHeight: 3 }}
    >
      <InputNumber min={0} max={75} precision={2} />
    </Form.Item>
  )
}

const PhotoPackageSelect = compose(inject('user'))(({ user, variables }) => {
  const { packages, photoPackageIds, singlePhotoPrice, unlimitedPackages } = variables
  const [search, setSearch] = useState()
  const { loading, data } = useQuery(photoPackagesQuery, {
    variables: {
      isDefault: [].includes(user.role) ? undefined : true,
      nameSearch: search,
      singlePhotoPrice,
    },
  })
  const photoPackages = data?.photoPackages ?? []
  const photoPackagesGroup = {}
  const photoUnlimitedPackages = []
  photoPackages.forEach((p) =>
    p.hasUnlimitedItems
      ? photoUnlimitedPackages.push(p)
      : photoPackagesGroup[p.minItemCount]
      ? photoPackagesGroup[p.minItemCount].push(p)
      : (photoPackagesGroup[p.minItemCount] = [p])
  )
  Object.keys(photoPackagesGroup).map((key) => photoPackagesGroup[key].sort((a, b) => a.name.localeCompare(b.name)))
  photoUnlimitedPackages.sort((a, b) => a.name.localeCompare(b.name))
  const selectedPackagesGroup = {}
  photoPackages.forEach((p) => packages.includes(p._id) && (selectedPackagesGroup[p.minItemCount] = p._id))
  if (loading && ![].includes(user.role)) {
    return <Spin style={{ display: 'block', lineHeight: '10vh' }} />
  }
  return (
    <>
      <UserPermission grantedTo={[]}>
        <Form.Item
          {...formItemLayout}
          name={['record', 'photoPackageIds']}
          labelAlign='left'
          label={`โปรโมชัน / แพ็กเกจ`}
          initialValue={photoPackageIds}
        >
          <Select
            mode='multiple'
            loading={loading}
            allowClear
            showSearch
            filterOption={false}
            placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}
            onSearch={(v) => setSearch(v)}
            onBlur={() => setSearch()}
            maxTagCount={6}
            style={{ width: '100%' }}
          >
            {photoPackages.map((p) => (
              <Select.Option key={p._id} value={p._id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </UserPermission>
      <UserPermission grantedTo={['superadmin', 'admin', 'broker']}>
        <Form.Item
          {...formItemLayout}
          name={['record', 'selectPackages']}
          labelAlign='left'
          label={<FormattedMessage id='app.photosInPackage' defaultMessage='Photos in Package' />}
          initialValue={Object.keys(selectedPackagesGroup)}
        >
          {Object.keys(photoPackagesGroup).length ? (
            <Checkbox.Group style={{ marginTop: '0.7em' }}>
              {Object.keys(photoPackagesGroup).map((key) => (
                <Row key={key}>
                  <Checkbox value={key}>
                    <FormattedMessage id='app.numberOfPhotos' defaultMessage='{photos} photos' values={{ photos: key }} />
                  </Checkbox>
                </Row>
              ))}
            </Checkbox.Group>
          ) : (
            <FormattedMessage id='app.noPackage' defaultMessage='No Package' />
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            !!getFieldValue(['record', 'selectPackages']).length &&
            Object.keys(photoPackagesGroup).map(
              (key) =>
                getFieldValue(['record', 'selectPackages']).includes(key) && (
                  <Form.Item
                    {...formItemLayout}
                    wrapperCol={{ sm: { span: 14 } }}
                    key={key}
                    name={['record', 'packages', `${key}`]}
                    labelAlign='left'
                    label={<FormattedMessage id='app.numberOfPhotos' defaultMessage='{photos} photos' values={{ photos: key }} />}
                    initialValue={selectedPackagesGroup[key]}
                  >
                    <Radio.Group>
                      {photoPackagesGroup[key].map((p, i) => (
                        <Row key={p._id}>
                          <Radio value={p._id} style={{ ...(i === 0 && { marginTop: '0.7em' }) }}>
                            {p.name}
                          </Radio>
                        </Row>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )
            )
          }
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['record', 'selectUnlimitedPackage']}
          labelAlign='left'
          label={<FormattedMessage id='app.unlimitedPackages' defaultMessage='Unlimited Packages' />}
          initialValue={!!unlimitedPackages}
          valuePropName={'checked'}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue(['record', 'selectUnlimitedPackage']) && (
              <Form.Item
                {...formItemLayout}
                wrapperCol={{ sm: { offset: 4, span: 14 } }}
                name={['record', 'unlimitedPackages']}
                initialValue={unlimitedPackages}
              >
                {Object.keys(photoUnlimitedPackages).length ? (
                  <Radio.Group style={{ marginTop: '0.7em' }}>
                    {photoUnlimitedPackages.map((p) => (
                      <Row key={p._id}>
                        <Radio value={p._id}>{p.name}</Radio>
                      </Row>
                    ))}
                  </Radio.Group>
                ) : (
                  <FormattedMessage id='app.noPackage' defaultMessage='No Package' />
                )}
              </Form.Item>
            )
          }
        </Form.Item>
      </UserPermission>
    </>
  )
})

const EventForm = ({ defaultValues, resourceId, onSubmit }) => {
  const [form] = Form.useForm()
  const { getFieldValue, resetFields, setFieldsValue } = form
  const [loading, setLoading] = useState(false)
  const [organizerChanged, setOrganizerChanged] = useState(false)
  const [brokerChanged, setBrokerChanged] = useState(false)
  const handleOrganizerId = () => {
    setOrganizerChanged(true)
  }
  const handleBrokerId = () => {
    setBrokerChanged(true)
  }
  const handleSinglePhotoPrice = () => {
    setFieldsValue({ record: { photoPackageEnabled: false } })
  }
  const handleFormSubmit = async (values) => {
    setLoading(true)
    const {
      date,
      shareInterval,
      media,
      frame,
      record: {
        officialPartner,
        shareEndsAt,
        registrationStartsAt,
        registrationEndsAt,
        registrationMethod,
        brokerId,
        brokerShare,
        brokerShareUnit,
        organizerId,
        organizerShare,
        organizerShareUnit,
        photographerShare,
        photographerNumber,
        photoPackageEnabled,
        packages,
        selectPackages,
        selectUnlimitedPackage,
        unlimitedPackages,
        photoPackageIds,
        ...record
      },
    } = values
    try {
      await onSubmit({
        media: {
          ...media,
          ...frame,
        },
        record: {
          ...record,
          shareEndsAt: moment(date)
            .endOf('day')
            .add(shareInterval - 1, 'days'),
          startDate: moment(date).startOf('day'),
          endDate: moment(date).startOf('day'),
          registrationStartsAt: registrationStartsAt?.startOf('day'),
          registrationEndsAt: registrationEndsAt?.endOf('day'),
          registrationMethod,
          photographerNumber: ['auto', 'manual'].includes(registrationMethod) ? photographerNumber : null,
          officialPartner,
          photoPackageEnabled,
          photoPackageIds: photoPackageEnabled
            ? [
                ...(photoPackageIds?.length ? photoPackageIds : []),
                ...(packages ? Object.keys(packages).reduce((prev, curr) => (packages[curr] ? [...prev, packages[curr]] : prev), []) : []),
                ...(selectUnlimitedPackage && unlimitedPackages?.length ? [unlimitedPackages] : []),
              ]
            : [],
          ...(officialPartner && {
            brokerId,
            brokerShare,
            organizerId,
            organizerShare,
            photographerShare,
          }),
        },
      })
      resetFields()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  return (
    <Form
      form={form}
      className='nonPrintDiv'
      onFinish={handleFormSubmit}
      scrollToFirstError={{ behavior: 'smooth' }}
      style={{ margin: '0 4em', padding: '2em 0 4em 0' }}
    >
      <h2>CREATE EVENT</h2>
      <div style={{ background: 'white', border: '1px solid LightGrey', borderRadius: '25px', padding: '1em' }}>
        <Form.Item
          style={{ color: 'black', fontSize: '1.2em', background: 'WhiteSmoke', borderRadius: '5px', padding: '7.5px 10px', margin: '15px' }}
        >
          <FormattedMessage id='app.generalInformation' defaultMessage='General Information' />
        </Form.Item>
        <Form.Item>
          {/* <Form.Item
            {...formItemLayout}
            name={['record', 'titleTH']}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
            labelAlign='left'
            label={`ชื่องานวิ่ง (ไทย)`}
            initialValue={defaultValues?.record?.titleTH}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item
            {...formItemLayout}
            name={['record', 'title']}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
            labelAlign='left'
            label={<FormattedMessage id='app.eventTitle' defaultMessage='Event Title' />}
            initialValue={defaultValues?.record?.title}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            {...formItemLayout}
            name={['record', 'location']}
            labelAlign='left'
            label={`สถานที่จัดงาน`}
            initialValue={defaultValues?.record?.location}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item> */}
          {config.platform === 'thai' && (
            <Form.Item
              {...formItemLayout}
              name={['record', 'province']}
              labelAlign='left'
              label={<FormattedMessage id='app.eventProvince' defaultMessage='Event Province' />}
              initialValue={defaultValues?.record?.province}
              // rules={[{ required: true }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder={<FormattedMessage id='app.chooseYourProvince' defaultMessage='Choose your province!' />}
                showSearch
              >
                {Object.values(province).map((item) => (
                  <Select.Option key={item.name.en} value={item.name.th}>
                    {item.name.th}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            {...formItemLayout}
            name={['date']}
            labelAlign='left'
            label={<FormattedMessage id='app.eventDate' defaultMessage='Event Date' />}
            initialValue={defaultValues?.record?.startDate && moment(defaultValues.record.startDate)}
            rules={[{ required: true }]}
          >
            <DatePicker disabledDate={(current) => moment().add(-30, 'days') > current} style={{ minWidth: '200px' }} />
          </Form.Item>
          <UserPermission grantedTo={['superadmin', 'admin']}>
            <Form.Item
              {...formItemLayout}
              name={['record', 'uploadableAt']}
              labelAlign='left'
              label={<FormattedMessage id='app.startUploadOn' defaultMessage='Start Upload On' />}
              initialValue={defaultValues?.record?.uploadableAt && moment(defaultValues.record.uploadableAt)}
            >
              <DatePicker showTime={{ minuteStep: 15, secondStep: 60 }} format='DD/MM/YYYY HH:mm:ss' style={{ minWidth: '200px' }} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name={['record', 'searchableAt']}
              labelAlign='left'
              label={<FormattedMessage id='app.openSearchOn' defaultMessage='Start Upload On' />}
              initialValue={defaultValues?.record?.searchableAt && moment(defaultValues?.record?.searchableAt)}
            >
              <DatePicker showTime={{ minuteStep: 30, secondStep: 60 }} format='DD/MM/YYYY HH:mm:ss' style={{ minWidth: '200px' }} />
            </Form.Item>
          </UserPermission>
          <Form.Item
            {...formItemLayout}
            name={['record', 'raceType']}
            labelAlign='left'
            label={<FormattedMessage id='app.raceType' defaultMessage='Race Type' />}
            initialValue={defaultValues?.record?.raceType}
          >
            <Radio.Group>
              <Radio value='road'>
                <FormattedMessage id='app.road' defaultMessage='Road' />
              </Radio>
              <Radio value='trail'>
                <FormattedMessage id='app.trail' defaultMessage='Trail' />
              </Radio>
              <Radio value='triathlon'>
                <FormattedMessage id='app.triathlon' defaultMessage='Triathlon' />
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['record', 'raceDistance']}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
            labelAlign='left'
            label={<FormattedMessage id='app.raceDistance' defaultMessage='Race Distance' />}
            initialValue={defaultValues?.record?.raceDistance}
          >
            <Radio.Group buttonStyle='solid'>
              <Radio value={100}>
                <FormattedMessage id='app.100k' defaultMessage='100k up' />
              </Radio>
              <Radio value={70}>
                <FormattedMessage id='app.70k' defaultMessage='70-99.9k' />
              </Radio>
              <Radio value={42}>
                <FormattedMessage id='app.42k' defaultMessage='42-69.9k' />
              </Radio>
              <Radio value={21}>
                <FormattedMessage id='app.21k' defaultMessage='21-41.9k' />
              </Radio>
              <Radio value={10}>
                <FormattedMessage id='app.10k' defaultMessage='10-20.9k' />
              </Radio>
              <Radio value={5}>
                <FormattedMessage id='app.5k' defaultMessage='5-9.9k' />
              </Radio>
              <Radio value={0}>
                <FormattedMessage id='app.0k' defaultMessage='0-4.9k' />
              </Radio>
            </Radio.Group>
            {/* <Checkbox.Group buttonStyle='solid' style={{ width: '100%' }}>
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <Checkbox value={0}>
                      <FormattedMessage id='app.0k' defaultMessage='0-4.9k' />
                    </Checkbox>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <Checkbox value={5}>
                      <FormattedMessage id='app.5k' defaultMessage='5-9.9k' />
                    </Checkbox>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <Checkbox value={10}>
                      <FormattedMessage id='app.10k' defaultMessage='10-20.9k' />
                    </Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <Checkbox value={21}>
                      <FormattedMessage id='app.21k' defaultMessage='21-41.9k' />
                    </Checkbox>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <Checkbox value={42}>
                      <FormattedMessage id='app.42k' defaultMessage='42-69.9k' />
                    </Checkbox>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <Checkbox value={70}>
                      <FormattedMessage id='app.70k' defaultMessage='70-99.9k' />
                    </Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <Checkbox value={100}>
                      <FormattedMessage id='app.100k' defaultMessage='100k up' />
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group> */}
          </Form.Item>
          <UserPermission grantedTo={['superadmin', 'admin']}>
            <Form.Item
              {...formItemLayout}
              name={['record', 'singlePhotoPrice']}
              labelAlign='left'
              label={<FormattedMessage id='app.singlePhotoPrice' defaultMessage='Single Photo Price' />}
              initialValue={defaultValues?.record?.singlePhotoPrice}
              rules={[{ required: true }]}
            >
              <Select onChange={handleSinglePhotoPrice} placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}>
                {[60, 70, 80].map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name={['record', 'officialPartner']}
              labelAlign='left'
              label={<FormattedMessage id='app.enablePartner' defaultMessage='Enable Partner' />}
              initialValue={defaultValues?.record?.officialPartner}
              valuePropName={'checked'}
            >
              <Switch />
            </Form.Item>
          </UserPermission>
          <Form.Item noStyle shouldUpdate>
            {() =>
              getFieldValue(['record', 'officialPartner']) && (
                <>
                  <Form.Item
                    {...formItemLayout}
                    name={['record', 'organizerId']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.organizer' defaultMessage='Organizer' />}
                    initialValue={defaultValues?.record?.organizerId}
                  >
                    <AdminUserSelect onChange={handleOrganizerId} role={'organizer'} />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name={['record', 'brokerId']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.broker' defaultMessage='Broker' />}
                    initialValue={defaultValues?.record?.brokerId}
                  >
                    <AdminUserSelect onChange={handleBrokerId} role={'broker'} />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                    labelAlign='left'
                    label={<FormattedMessage id='app.sharePercentage' defaultMessage='% Share' />}
                  >
                    <Row type='flex'>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                        <OrganizerDetail
                          changed={organizerChanged}
                          defaultValues={defaultValues}
                          organizerId={getFieldValue(['record', 'organizerId'])}
                          form={{ setFieldsValue }}
                        />
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                        <BrokerDetail
                          changed={brokerChanged}
                          defaultValues={defaultValues}
                          brokerId={getFieldValue(['record', 'brokerId'])}
                          form={{ setFieldsValue }}
                        />
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                        <Form.Item
                          labelCol={{ sm: { span: 8 } }}
                          wrapperCol={{ sm: { span: 16 } }}
                          name={['record', 'photographerShare']}
                          labelAlign='left'
                          label={<FormattedMessage id='app.photographer' defaultMessage='Photographer' />}
                          initialValue={defaultValues?.record?.photographerShare}
                          extra={<FormattedMessage id='app.range' defaultMessage='Range {min}-{max}' values={{ min: 0, max: 75 }} />}
                          style={{ lineHeight: 3 }}
                        >
                          <InputNumber min={0} max={75} precision={2} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    {...formItemLongLayout}
                    labelCol={{ sm: { offset: 1, span: 8 } }}
                    name={['record', 'consentDocumentUrl']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.consentDocument' defaultMessage='Consent Document for selling photos on ThaiRun Photo' />}
                    initialValue={defaultValues?.record?.consentDocumentUrl}
                    // rules={[{ required: true, message: <FormattedMessage id="app.consentDocumentRequired" defaultMessage="Please upload document" /> }]}
                  >
                    <CloudUploader provider='gs' path='documents' />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() =>
              getFieldValue(['record', 'singlePhotoPrice']) && (
                <Form.Item
                  {...formItemLayout}
                  name={['record', 'photoPackageEnabled']}
                  labelAlign='left'
                  label={<FormattedMessage id='app.enablePackages' defaultMessage='Enable Packages' />}
                  initialValue={defaultValues?.record?.photoPackageEnabled}
                  valuePropName={'checked'}
                >
                  <Switch />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() =>
              getFieldValue(['record', 'photoPackageEnabled']) && (
                <PhotoPackageSelect
                  variables={{
                    packages: defaultValues?.record?.photoPackages?.filter((p) => !p?.hasUnlimitedItems)?.map((p) => p._id) ?? [],
                    photoPackageIds: defaultValues?.record?.photoPackageIds,
                    singlePhotoPrice: getFieldValue(['record', 'singlePhotoPrice']),
                    unlimitedPackages: defaultValues?.record?.photoPackages?.find((p) => !!p?.hasUnlimitedItems)?._id,
                  }}
                />
              )
            }
          </Form.Item>
          {
            <UserPermission grantedTo={['superadmin', 'admin']}>
              <Form.Item
                {...formItemLayout}
                name={['record', 'participantCount']}
                labelAlign='left'
                label={<FormattedMessage id='app.participantCount' defaultMessage='participantCount' />}
                initialValue={defaultValues?.record?.participantCount}
                // rules={[{ required: true }]}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name={['shareInterval']}
                labelAlign='left'
                label={<FormattedMessage id='app.shareDuration' defaultMessage='Share Duration' />}
                initialValue={
                  defaultValues?.record?.shareInterval ??
                  moment(defaultValues?.record?.shareEndsAt).diff(defaultValues?.record?.startDate, 'days') + 1 ??
                  (getFieldValue(['record', 'officialPartner']) ? 30 : undefined)
                }
                rules={[{ type: 'number', min: 1, message: 'Minimum value is 1' }]}
              >
                <InputNumber
                  disabled
                  addonAfter={<FormattedMessage id='app.daySinceEventDate' defaultMessage='Day (Since Event Date)' />}
                  style={{ verticalAlign: 'middle' }}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name={['record', 'photoDownloadMode']}
                labelAlign='left'
                label={<FormattedMessage id='app.downloadMode' defaultMessage='Download Mode' />}
                initialValue={defaultValues?.record?.photoDownloadMode}
                rules={[{ required: true, message: 'Please Select Mode!' }]}
              >
                <Select style={{ width: '240px' }} placeholder='Please select a mode'>
                  <Select.Option key='free' value='free'>
                    <FormattedMessage id='app.free' defaultMessage='Free' />
                  </Select.Option>
                  {/* <Select.Option key="restricted" value="restricted"><FormattedMessage id="app.restricted" defaultMessage="Restricted" /></Select.Option> */}
                  <Select.Option key='priced' value='priced'>
                    <FormattedMessage id='app.priced' defaultMessage='Priced' />
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name={['record', 'hidden']}
                labelAlign='left'
                label={<FormattedMessage id='app.hidden' defaultMessage='Hidden' />}
                initialValue={defaultValues?.record?.hidden}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name={['record', 'uploadNotice']}
                labelAlign='left'
                label={<FormattedMessage id='app.uploadNotice' defaultMessage='Upload Notice' />}
                initialValue={defaultValues?.record?.uploadNotice}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name={['record', 'photoTagFaces']}
                labelAlign='left'
                label={<FormattedMessage id='app.faceSearch' defaultMessage='Face Search' />}
                initialValue={defaultValues?.record?.photoTagFaces}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name={['record', 'photoTagText']}
                labelAlign='left'
                label={<FormattedMessage id='app.bibSearch' defaultMessage='BIB Search' />}
                initialValue={defaultValues?.record?.photoTagText}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name={['record', 'approved']}
                labelAlign='left'
                label={<FormattedMessage id='app.approved' defaultMessage='Approved' />}
                initialValue={defaultValues?.record?.approved}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
              {/* <Form.Item {...formItemLayout} name={['record', 'keywords']} labelAlign='left' label={<FormattedMessage id="app.keywords" defaultMessage="Keywords" />} initialValue={defaultValues?.record?.keywords}>
                <Select mode="tags" tokenSeparators={[',']} placeholder={'bs21,bs21-2018,bangsaen21'} style={{ width: '100%' }} />
              </Form.Item> */}
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.username' defaultMessage='Username' />}>
                <span>{defaultValues?.record?.createdByUser?.username}</span>
              </Form.Item>
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.email' defaultMessage='Email' />}>
                <span>{defaultValues?.record?.createdByUser?.profile.email}</span>
              </Form.Item>
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.phone' defaultMessage='Phone' />}>
                <span>{defaultValues?.record?.createdByUser?.profile.phone}</span>
              </Form.Item>
              {resourceId && (
                <>
                  <Form.Item {...formItemLayout} labelAlign='left' label='Quickbooks Customer'>
                    <QbCustomer eventId={resourceId} />
                  </Form.Item>
                  <Form.Item {...formItemLayout} labelAlign='left' label='Quickbooks Vendor'>
                    <QbVendor eventId={resourceId} />
                  </Form.Item>
                </>
              )}
            </UserPermission>
          }
        </Form.Item>
        <UserPermission grantedTo={['superadmin', 'admin']}>
          <Form.Item style={{ color: 'black', fontSize: '1.2em', background: 'WhiteSmoke', borderRadius: '5px', padding: '10px', margin: '15px' }}>
            <Col>
              <FormattedMessage id='app.photographerInformation' defaultMessage='Photographer Information' />
            </Col>
          </Form.Item>
          <Form.Item>
            <Form.Item
              labelCol={{ xs: { span: 24 }, sm: { offset: 1, span: 3 } }}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 20 } }}
              name={['record', 'registrationMethod']}
              labelAlign='left'
              label={<FormattedMessage id='app.registrationMethod' defaultMessage='Photographer Selection' />}
              initialValue={defaultValues?.record?.registrationMethod}
            >
              <Radio.Group>
                <Radio value='manual'>
                  <FormattedMessage id='app.registrationMethod.manual' defaultMessage='Manual' />
                </Radio>
                <Radio value='auto'>
                  <FormattedMessage id='app.registrationMethod.auto' defaultMessage='Automatic' />
                </Radio>
                <Radio value='free'>
                  <FormattedMessage id='app.registrationMethod.free' defaultMessage='Open' />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() =>
                ['auto', 'manual'].includes(getFieldValue(['record', 'registrationMethod'])) && (
                  <Form.Item
                    {...formItemLayout}
                    name={['record', 'photographerNumber']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.photographerNumber' defaultMessage='Photographer Limit' />}
                    initialValue={defaultValues?.record?.photographerNumber}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                )
              }
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() => (
                <>
                  <Form.Item
                    {...formItemLayout}
                    name={['record', 'registrationStartsAt']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.registrationStartsAt' defaultMessage='Registration Starts' />}
                    initialValue={defaultValues?.record?.registrationStartsAt && moment(defaultValues?.record?.registrationStartsAt)}
                    rules={[{ required: getFieldValue(['record', 'registrationMethod']) === 'auto' ? false : true }]}
                  >
                    <DatePicker />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name={['record', 'registrationEndsAt']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.registrationEndsAt' defaultMessage='Registration Ends' />}
                    initialValue={defaultValues?.record?.registrationEndsAt && moment(defaultValues?.record?.registrationEndsAt)}
                    rules={[{ required: getFieldValue(['record', 'registrationMethod']) === 'auto' ? false : true }]}
                  >
                    <DatePicker />
                  </Form.Item>
                </>
              )}
            </Form.Item>
            {/* <Form.Item {...formItemLayout} labelAlign='left' label={`ส่งคำเชิญให้ช่างภาพ`}>
              <AdminUserSelect role={'photographer'} />
            </Form.Item>
            <Form.Item {...formItemLayout} labelAlign='left' label={`ช่างภาพ BLACKLIST`}>
              <AdminUserSelect role={'photographer'} />
            </Form.Item> */}
          </Form.Item>
        </UserPermission>
        <Form.Item style={{ color: 'black', fontSize: '1.2em', background: 'WhiteSmoke', borderRadius: '5px', padding: '10px', margin: '15px' }}>
          <Col>
            <FormattedMessage id='app.approvalDocument' defaultMessage='Approval Document' />
          </Col>
        </Form.Item>
        <Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['record', 'coverUrl']}
            labelAlign='left'
            label={<FormattedMessage id='app.coverImage' defaultMessage='Cover Image' />}
            initialValue={defaultValues?.record?.coverUrl}
            rules={[{ required: true }]}
          >
            <CloudImageUploader path='events/cover' provider='cf' accept='image/jpeg' resize={{ maxWidth: 1200, mode: 'contain' }} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() =>
              getFieldValue(['record', 'officialPartner']) && (
                <Form.Item
                  {...formItemLayout}
                  name={['frame']}
                  labelAlign='left'
                  label={<FormattedMessage id='app.eventFrame' defaultMessage='Photo Frame' />}
                >
                  <FrameImageInput
                    portraitPreviewUrl={defaultValues?.record?.photoFrame?.portraitPreviewUrl}
                    landscapePreviewUrl={defaultValues?.record?.photoFrame?.landscapePreviewUrl}
                  />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item
            {...formItemLongLayout}
            name={['record', 'contractDocumentUrl']}
            labelAlign='left'
            label={<FormattedMessage id='app.contractDocument' defaultMessage='Contract Document for partner' />}
            initialValue={defaultValues?.record?.contractDocumentUrl}
          >
            <CloudUploader provider='gs' path='documents' accept='.pdf,image/*' />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginTop: 20 }}>
          <Form.Item {...tailFormItemLayout}>
            <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
              <FormattedMessage id='app.submit' defaultMessage='Submit' />
            </Button>
          </Form.Item>
        </Form.Item>
      </div>
    </Form>
  )
}

export default EventForm
