import React from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { useLazyQuery, useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import { message, Form, Input, Button, Upload, Checkbox, Select, Row, Col } from 'antd'
import { HomeOutlined, LockOutlined, MailOutlined, PhoneOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons'
import adminUserByUsernameQuery from '~/graphql/queries/adminUserByUsername.gql'
import registerAdminUserMutation from '~/graphql/mutations/registerAdminUser.gql'
import LocaleSwitcher from '~/components/LocaleSwitcher'
import province from '~/assets/jsons/changwats'
import supportedBanks from '~/data/supportedBanks.json'
import logo from './assets/images/logo.png'
import config from './config'

const Option = Select.Option
const fileReader = new FileReader() // not available on server
const fileData = (url = '') => {
  const matches = url.match(/^data:(.+);base64,(.*)$/)
  if (!matches) {
    return null
  }
  const [, mimeType, base64data] = matches
  return {
    mimeType,
    base64data,
  }
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const Register = compose(
  withApollo,
  injectIntl
)(({ history, intl }) => {
  const [form] = Form.useForm()
  const { getFieldValue, setFieldsValue } = form
  const [adminUserByUsername] = useLazyQuery(adminUserByUsernameQuery, { fetchPolicy: 'network-only' })
  const [registerUser] = useMutation(registerAdminUserMutation)
  const beforeUpload = (name) =>
    function (file, files) {
      fileReader.onload = (e) => {
        file.thumbUrl = fileReader.result
        setFieldsValue({
          [name]: file,
        })
      }
      fileReader.readAsDataURL(file)
      return false
    }
  const profileFileProps = {
    name: 'profileFile',
    accept: 'image/*',
    action: '',
    beforeUpload: beforeUpload('profileFile'),
    fileList: getFieldValue('profileFile') ? [getFieldValue('profileFile')] : [],
    showUploadList: false,
  }
  const logoFileProps = {
    name: 'logoFile',
    accept: '.png',
    action: '',
    beforeUpload: beforeUpload('logoFile'),
    fileList: getFieldValue('logoFile') ? [getFieldValue('logoFile')] : [],
    showUploadList: false,
  }
  const handleSubmit = async (values) => {
    const {
      record: { confirmPassword, ...record },
      profileFile,
      logoFile,
    } = values
    const profileUrl = profileFile?.thumbUrl
    const logoUrl = logoFile?.thumbUrl
    try {
      const {
        data: {
          registerAdminUser: {
            record: { displayName },
          },
        },
      } = await registerUser({
        variables: {
          record: {
            ...record,
            role: 'photographer',
          },
          profile: fileData(profileUrl),
          logo: fileData(logoUrl),
        },
      })
      message.info(`Successfully registered ${displayName}`)
      history.push('/login')
    } catch (err) {
      err.graphQLErrors.forEach((error) => message.error(intl.formatMessage({ id: `api.errors.${error.message}`, defaultMessage: 'Error' })))
    }
  }
  const checkConfirm = (rule, value, callback) => {
    if (!value || getFieldValue(['record', 'password']) === value) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('Two passwords that you enter is inconsistent!'))
  }
  const usernameValidator = async (rule, value, callback) => {
    if (!value) {
      return Promise.resolve()
    }
    const {
      data: { adminUserByUsername: adminUser },
    } = await adminUserByUsername({ variables: { username: value } })
    if (adminUser) {
      return Promise.reject(intl.formatMessage({ id: 'app.usernameExists', defaultMessage: 'Username already exists' }))
    }
    return Promise.resolve()
  }
  // TODO: if login notify
  return (
    <Form form={form} onFinish={handleSubmit} className='login-form' style={{ width: '400px', margin: 'auto', marginTop: '2em' }}>
      <Form.Item>
        <Row type='flex' justify='space-between'>
          <Col>
            <img src={logo} style={{}} alt='Thai.run' />
          </Col>
          <Col>
            <LocaleSwitcher />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'nationalId']}
        label={intl.formatMessage({ id: 'app.citizenID', defaultMessage: 'Citizen ID' })}
        rules={[{ required: true, message: '' }]}
      >
        <Input placeholder={intl.formatMessage({ id: 'app.citizenID', defaultMessage: 'citizen-id' })} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'firstName']}
        label={intl.formatMessage({ id: 'app.firstName', defaultMessage: 'First Name' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourFirstName', defaultMessage: 'Enter your first name!' }) }]}
      >
        <Input placeholder={intl.formatMessage({ id: 'app.firstName', defaultMessage: 'First Name' })} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'lastName']}
        label={intl.formatMessage({ id: 'app.lastName', defaultMessage: 'Last Name' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourLastName', defaultMessage: 'Enter your last name!' }) }]}
      >
        <Input placeholder={intl.formatMessage({ id: 'app.lastName', defaultMessage: 'Last Name' })} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'email']}
        label={intl.formatMessage({ id: 'app.email', defaultMessage: 'Email' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourEmail', defaultMessage: 'Enter your email!' }) }]}
      >
        <Input
          prefix={<MailOutlined style={{ fontSize: 13 }} />}
          placeholder={intl.formatMessage({ id: 'app.email', defaultMessage: 'Email' })}
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'phone']}
        label={intl.formatMessage({ id: 'app.phone', defaultMessage: 'Phone' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourPhone', defaultMessage: 'Enter your phone!' }) }]}
      >
        <Input
          prefix={<PhoneOutlined style={{ fontSize: 13 }} />}
          placeholder={intl.formatMessage({ id: 'app.telephone', defaultMessage: 'Telephone' })}
        />
      </Form.Item>

      {/* <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'address']}
        label={intl.formatMessage({ id: 'app.address', defaultMessage: 'Address' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourAddress', defaultMessage: 'Enter your address!' }) }]}
      >
        <Input
          prefix={<HomeOutlined style={{ fontSize: 13 }} />}
          placeholder={intl.formatMessage({ id: 'app.address', defaultMessage: 'Address' })}
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'road']}
        label={intl.formatMessage({ id: 'app.road', defaultMessage: 'Road' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourRoad', defaultMessage: 'Road' }) + '!' }]}
      >
        <Input prefix={<HomeOutlined style={{ fontSize: 13 }} />} placeholder={intl.formatMessage({ id: 'app.road', defaultMessage: 'Road' })} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'moo']}
        label={intl.formatMessage({ id: 'app.moo', defaultMessage: 'Moo/Building/Floor' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourMoo', defaultMessage: 'Enter your Moo/Building/Floor!' }) }]}
      >
        <Input
          prefix={<HomeOutlined style={{ fontSize: 13 }} />}
          placeholder={intl.formatMessage({ id: 'app.moo', defaultMessage: 'Moo/Building/Floor' })}
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'subdistrict']}
        label={intl.formatMessage({ id: 'app.subdistrict', defaultMessage: 'Subdistrict' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourSubdistrict', defaultMessage: 'Enter your Subdistrict!' }) }]}
      >
        <Input
          prefix={<HomeOutlined style={{ fontSize: 13 }} />}
          placeholder={intl.formatMessage({ id: 'app.subdistrict', defaultMessage: 'Subdistrict' })}
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'profile', 'district']}
        label={intl.formatMessage({ id: 'app.district', defaultMessage: 'District' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourDistrict', defaultMessage: 'Enter your District!' }) }]}
      >
        <Input
          prefix={<HomeOutlined style={{ fontSize: 13 }} />}
          placeholder={intl.formatMessage({ id: 'app.district', defaultMessage: 'District' })}
        />
      </Form.Item> */}

      {config.platform === 'thai' && <>
        <Form.Item
          {...formItemLayout}
          name={['record', 'profile', 'province']}
          label={intl.formatMessage({ id: 'app.province', defaultMessage: 'Province' })}
          rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourProvince', defaultMessage: 'Enter your province!' }) }]}
        >
          <Select
            style={{ width: '100%' }}
            prefix={<HomeOutlined style={{ fontSize: 13 }} />}
            placeholder={intl.formatMessage({ id: 'app.province', defaultMessage: 'Province' })}
            showSearch
          >
            {Object.values(province).map((item) => (
              <Select.Option key={item.name.en} value={item.name.th}>
                {item.name.th}
              </Select.Option>
            ))}
          </Select>
          {/* <Input prefix={<HomeOutlined style={{ fontSize: 13 }} />} placeholder={intl.formatMessage({ id: 'app.province', defaultMessage: 'Province' })} /> */}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['record', 'profile', 'postalCode']}
          label={intl.formatMessage({ id: 'app.postalCode', defaultMessage: 'Postal Code' })}
          rules={[{ required: true, message: intl.formatMessage({ id: 'app.enterYourPostalCode', defaultMessage: 'Enter your postal code!' }) }]}
        >
          <Input
            prefix={<HomeOutlined style={{ fontSize: 13 }} />}
            placeholder={intl.formatMessage({ id: 'app.postalCode', defaultMessage: 'Postal Code' })}
          />
        </Form.Item>
      </>}
      <Form.Item
        {...formItemLayout}
        name={['record', 'displayName']}
        label={intl.formatMessage({ id: 'app.displayName', defaultMessage: 'Display Name' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'app.pleaseInputYourDisplayName', defaultMessage: 'Please input your display name!' }),
          },
        ]}
      >
        <Input placeholder={intl.formatMessage({ id: 'app.displayName', defaultMessage: 'Display Name' })} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'username']}
        label={intl.formatMessage({ id: 'app.username', defaultMessage: 'User Name' })}
        validateTrigger={[]}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'app.pleaseInputYourUsername', defaultMessage: 'Please input your username!' }) },
          {
            pattern: /^[A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*$/i,
            message: intl.formatMessage({
              id: 'app.usernameValidationMessage1',
              defaultMessage: 'Please input only English charactor and digit and - , . ',
            }),
          },
          { validator: usernameValidator },
        ]}
        extra={intl.formatMessage({ id: 'app.usernameInfo', defaultMessage: 'Username may contain english alphabet, number, dot (.) or hyphen (-)' })}
      >
        <Input
          prefix={<UserOutlined style={{ fontSize: 13 }} />}
          placeholder={intl.formatMessage({ id: 'app.username', defaultMessage: 'Username' })}
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'password']}
        label={intl.formatMessage({ id: 'app.password', defaultMessage: 'Password' })}
        hasFeedback
        rules={[
          { required: true, message: intl.formatMessage({ id: 'app.pleaseInputYourPassword', defaultMessage: 'Please input your Password!' }) },
        ]}
      >
        <Input
          prefix={<LockOutlined style={{ fontSize: 13 }} />}
          type='password'
          placeholder={intl.formatMessage({ id: 'app.password', defaultMessage: 'Password' })}
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'confirmPassword']}
        label={intl.formatMessage({ id: 'app.confirmPassword', defaultMessage: 'Confirm Password' })}
        dependencies={['record', 'password']}
        hasFeedback
        rules={[
          { required: true, message: intl.formatMessage({ id: 'app.confirmYourPassword', defaultMessage: 'Confirm your Password!' }) },
          { validator: checkConfirm },
        ]}
      >
        <Input
          prefix={<LockOutlined style={{ fontSize: 13 }} />}
          type='password'
          placeholder={intl.formatMessage({ id: 'app.confirmPassword', defaultMessage: 'Confirm Password' })}
        />
      </Form.Item>
      {config.platform === 'thai' && <>
        <Form.Item name={['link_bank_account']} style={{ textAlign: 'center' }} initialValue={true} valuePropName={'checked'}>
          <Checkbox>{intl.formatMessage({ id: 'app.linkBankAccount', defaultMessage: 'Link Bank Account' })}</Checkbox>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() =>
            getFieldValue(['link_bank_account']) === true && (
              <>
                <Form.Item
                  {...formItemLayout}
                  name={['record', 'bankAccount', 'brand']}
                  label={intl.formatMessage({ id: 'app.selectBank', defaultMessage: 'Select Bank' })}
                  initialValue={''}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'app.pleaseSelectBank', defaultMessage: 'Please Select Bank!' }) }]}
                >
                  <Select>
                    <Option key={''} value=''>
                      {intl.formatMessage({ id: 'app.selectBank', defaultMessage: 'Select Bank' })}
                    </Option>
                    {supportedBanks.map((bankId) => (
                      <Option key={bankId} value={bankId}>
                        {intl.formatMessage({ id: `app.banks.${bankId}` })}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name={['record', 'bankAccount', 'number']}
                  label={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })}
                  initialValue={''}
                  rules={[
                    {
                      required: true,
                      len: 10,
                      message: intl.formatMessage({ id: 'app.pleaseInputYourAccountNumber', defaultMessage: 'Please input your account number!' }),
                    },
                  ]}
                >
                  <Input placeholder={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })} />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name={['record', 'bankAccount', 'name']}
                  label={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })}
                  initialValue={''}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'app.pleaseInputYourAccountName', defaultMessage: 'Please input your account name!' }),
                    },
                  ]}
                >
                  <Input placeholder={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })} />
                </Form.Item>
              </>
            )
          }
        </Form.Item>
      </>}
      {config.platform === 'thai' && <>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name={['profileFile']} style={{ textAlign: 'center' }}>
              <Upload {...profileFileProps}>
                {getFieldValue(['profileFile']) ? (
                  <Button style={{ height: 'unset', padding: '8px' }}>
                    <img src={getFieldValue(['profileFile'])?.thumbUrl} style={{ height: '150px' }} alt='' />
                  </Button>
                ) : (
                  <Button>
                    <UploadOutlined /> {intl.formatMessage({ id: 'app.selectProfileLogo', defaultMessage: 'Select Profile Image' })} (.jpg)
                  </Button>
                )}
              </Upload>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name={['logoFile']} style={{ textAlign: 'center' }}>
              <Upload {...logoFileProps}>
                {getFieldValue(['logoFile']) ? (
                  <Button style={{ height: 'unset', padding: '8px', background: 'gainsboro' }}>
                    <img src={getFieldValue(['logoFile'])?.thumbUrl} style={{ maxHeight: '120px', maxWidth: '240px' }} alt='' />
                  </Button>
                ) : (
                  <Button>
                    <UploadOutlined /> {intl.formatMessage({ id: 'app.selectOverlayLogo', defaultMessage: 'Select Overlay Logo' })} (.png)
                  </Button>
                )}
              </Upload>
            </Form.Item>
          )}
        </Form.Item>
      </>}
      <Form.Item>
        <Button type='primary' htmlType='submit' style={{ width: '100%', marginBottom: '6px' }}>
          {intl.formatMessage({ id: 'app.register', defaultMessage: 'register' })}
        </Button>
        <Button style={{ width: '100%' }}>
          <Link to='/login'>{intl.formatMessage({ id: 'app.logIn', defaultMessage: 'Log in' })}</Link>
        </Button>
      </Form.Item>
    </Form>
  )
})

export default Register
