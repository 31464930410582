import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import eventsQuery from '../graphql/queries/events.gql'

const EventSelect = ({ style, onChange, value, myEvent }) => {
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(eventsQuery, {
    variables: {
      search,
      myEvent,
      // officialPartner,
      // organizerIds: organizers?.length ? { in: organizers } : undefined,
      // photoFileDeletedAt: photoFileDeletedAt ? { gte: photoFileDeletedAt } : undefined,
      limit: 10,
    },
  })
  if (error) {
    return 'Error'
  }
  // const handleSelect = () => {
  //   // const eventIds = data?.events.map((item) => item._id)
  //   onChange(eventId)
  // }
  return (
    <Select
      loading={loading}
      allowClear
      showSearch
      filterOption={false}
      placeholder={<FormattedMessage id='app.selectEvent' defaultMessage='Select Event...' />}
      onChange={onChange}
      onSearch={(v) => setSearch(v)}
      onBlur={() => setSearch()}
      value={value}
      maxTagCount={6}
      style={{ width: 200, ...style }}
      // dropdownRender={(menu) => (
      //   <div>
      //     {menu}
      //     {selectAll && (
      //       <div>
      //         <Divider style={{ margin: '4px 8px' }} />
      //         <div onMouseDown={(e) => e.preventDefault()} onClick={handleSelectAll} style={{ padding: '4px 8px', cursor: 'pointer' }}>
      //           <FormattedMessage id='app.allEvents' defaultMessage='All Events' />
      //           {` (${data?.events?.length})`}
      //         </div>
      //       </div>
      //     )}
      //   </div>
      // )}
    >
      {data?.events?.map((item) => (
        <Select.Option key={item._id} value={item._id}>
          {item.title}
        </Select.Option>
      ))}
    </Select>
  )
}

export default EventSelect
