import React, { useState } from 'react'
import { Button, Modal, message, Tag, Input, Form, Popover } from 'antd'
import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import updateEventMutation from '~/graphql/mutations/updateEvent.gql'
import eventItemsQuery from '~/graphql/queries/eventItems.gql'
import { FormattedMessage, useIntl } from 'react-intl'

const EventApproveButton = ({ event }) => {
  const [form] = Form.useForm()
  const { getFieldsValue, resetFields } = form
  const intl = useIntl()
  const [approveEvent] = useMutation(updateEventMutation)
  const [open, setOpen] = useState(false)
  const approve = async (approved) => {
    const values = getFieldsValue(true)
    const { approvalMessage } = values
    try {
      await approveEvent({
        variables: {
          _id: event._id,
          record: {
            approved,
            approvalMessage
          }
        },
        refetchQueries: [{ query: eventItemsQuery }]
      })
      message.success('Approval success', 4)
    } catch (err) {
      message.error(err)
    } finally {
      resetFields()
      setOpen(false)
    }
  }
  const handleClick = () => setOpen(true)

  return <>
    {typeof event.approved !== 'boolean'
      ? <Button onClick={handleClick}><CloseOutlined />|<CheckOutlined /></Button>
      : (event.approved
        ? <Tag color="green"><CheckCircleOutlined /> <FormattedMessage id="app.approved" defaultMessage="Approved" /></Tag>
        : <Popover content={event.approvalMessage}><Tag color="red"><CloseCircleOutlined /> <FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Tag></Popover>
      )
    }
    <Modal
      open={open}
      closable
      // onCancel={(e) => console.log(e, e.button, e.target)}
      onCancel={() => setOpen(false)}
      maskClosable
      footer={
        <>
          <Button icon={<CloseOutlined />} type="primary" onClick={() => approve(false)}><FormattedMessage id="app.reject" defaultMessage="Reject" danger /></Button>
          <Button icon={<CheckOutlined />} type="primary" onClick={() => approve(true)}><FormattedMessage id="app.approve" defaultMessage="Approve" /></Button>
        </>
      }
    >
      <React.Fragment>
        <div><FormattedMessage id="app.review" defaultMessage="Review" /> {event.title}</div>
        <div><FormattedMessage id="app.raceType" defaultMessage="Race Type" />: {event.raceType}</div>
        {event.consentDocumentUrl && <a href={event.consentDocumentUrl} target="_blank" rel="noopener noreferrer">{event.consentDocumentUrl}</a>}
        {event.consentDocumentUrl ? <div>
          <iframe width="100%" height="400px" title="Consent Document" src={event.consentDocumentUrl} />
        </div> : 'No Document'}
        <Form form={form}>
          <Form.Item name={['approvalMessage']}>
            <Input.TextArea placeholder={intl.formatMessage({ id: 'app.reason', defaultMessage: 'Reason' })} />
          </Form.Item>
        </Form>
      </React.Fragment>
    </Modal>
  </>
}

export default EventApproveButton
