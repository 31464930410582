import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Select } from 'antd'

const paymentMethodInput = [
    { _id: 0, name: <FormattedMessage id='app.allPaymentMethod' defaultMessage='All Payment Method' /> },
    { _id: 1, method: 'omise_card', name: 'Credit/Debit Card' },
    { _id: 2, method: 'omise_truemoney', name: 'Truemoney' },
    // { _id: 3, method: 'omise_netbank', name: 'Netbank' },
    { _id: 3, method: 'linepay_web', name: 'Linepay' },
    { _id: 4, method: 'scb_qr', name: 'QR Code' },
    // {_id: 6, method:'OMISE', name: 'OMISE', input:'OMISE'},
  ]

const PaymentMethod = ({ style, onChange, value }) => {

  return (
    <Select
      allowClear
      // showSearch
      filterOption={false}
      placeholder={<FormattedMessage id='app.paymentMethod' defaultMessage='Payment Method' />}
      onChange={onChange}
      // onSearch={(v) => setSearch(v)}
      // onBlur={() => setSearch()}
      value={value}
      // maxTagCount={6}
      style={{ width: 200, ...style }}
    >
      {paymentMethodInput.map((item) => (
        <Select.Option key={item._id} value={item.method}>
        {item.name}
        </Select.Option>
        ))}
    </Select>
  )
}

export default PaymentMethod
