import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
// import { withRouter } from 'react-router'
import { graphql } from '@apollo/client/react/hoc'
import {
  Table,
  Pagination,
  Tag,
  Button,
  Popover,
  Affix,
  Checkbox,
  Space
} from 'antd'
import { BarsOutlined, DeleteOutlined, PictureOutlined } from '@ant-design/icons'
// import qs from 'querystring'
import _get from 'lodash/get'
import './PhotoList.css'
import './PhotoTag.css'
// import { inject, observer } from 'mobx-react'
import { inject, observer } from 'mobx-react'
import withProps from 'recompose/withProps'
import listPhotosQuery from '~/graphql/queries/listPhotos.gql'
import deletePhotosMutation from '~/graphql/mutations/deletePhotos.gql'

const limit = 100

@inject('user')
@inject('router')
@withProps(({ user, router }) =>
  ({ userId: (user.role === 'superadmin' || user.role === 'admin' || user.role === 'accounting')
    ? _get(router, 'location.query.userId')
    : user._id })
)
@graphql(listPhotosQuery, {
  options: ({ router, userId }) => {
    const { page } = router.location.query
    return { variables:
       {
         skip: (page - 1) * limit,
         limit,
         photos_operators: {
           reported: {ne: null}
         },
         photoCount_operators: {
           reported: {ne: null}
         }
       }
    }
  }
})
@graphql(deletePhotosMutation, { name: 'deletePhotos' })
@observer
class PhotoReportList extends Component {
  state = {
    mode: 'image',
    selectedPhotos: [],
    holdShift: false
  }

  componentDidMount () {
    document.addEventListener('keydown', this._keyDown)
    document.addEventListener('keyup', this._keyUp)
  }

  onPageChange = (page, pageSize) => {
    this.props.onPageChange(page)
  }

  onSelectPhoto = id => {
    if (!this.isSelectedPhoto(id)) {
      this.state.selectedPhotos.push(id)
      this.setState({
        selectedPhotos: this.state.selectedPhotos
      })
    } else {
      let position = this.state.selectedPhotos.indexOf(id)
      this.state.selectedPhotos.splice(position, 1)
      this.setState({ selectedPhotos: this.state.selectedPhotos })
    }
  }

  onSelectChange = selectedPhotos => {
    this.setState({ selectedPhotos })
  }

  isSelectedPhoto = id => {
    if (this.state.selectedPhotos.indexOf(id) < 0) {
      return false
    } else {
      return true
    }
  }

  handleDeletePhotos = () => {
    // console.log("Delte : " + this.state.selectedPhotos)
    this.props
      .deletePhotos({
        variables: { _ids: this.state.selectedPhotos }
        // refetchQueries: [{
        //   query: listPhotos
        // }]
      })
      .then(console.log('Delete Success!'))
  }

  _keyDown = event => {
    if (event.keyCode === 16) {
      this.setState({
        holdShift: true
      })
    }
  }

  _keyUp = event => {
    if (event.keyCode === 16) {
      this.setState({
        holdShift: false
      })
    }
  }

  _multiSelect = id => {
    if (this.state.holdShift) {
      this.onSelectPhoto(id)
    } else {
      const { router } = this.props
      router.push(`/photos/${id}/edit`)
    }
  }

  changeViewMode = (mode) => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      view: mode
    }
  }

  render () {
    const { data, router } = this.props
    const { loading } = data
    if (loading) {
      return <div>Loading...</div>
    }

    const { view, page } = router.location.query
    const { selectedPhotos } = this.state
    const rowSelection = {
      selectedRowKeys: selectedPhotos,
      onChange: this.onSelectChange
    }
    const photos = _get(this.props.data, 'photos', [])
    const photoCount = _get(this.props.data, 'photoCount', 0)
    if (view === 'image') {
      return (<div>
        <Affix>
          <div style={{ float: 'right' }}>
            <Space.Compact block style={{ paddingRight: '10px' }}>
              <Button
                type="submit"
                disabled={this.state.selectedPhotos.length === 0}
                onClick={this.handleDeletePhotos}
              >
                <span><FormattedMessage id="app.deletePhotos" defaultMessage="Delete Photos"/> <DeleteOutlined /></span>
              </Button>
            </Space.Compact>
            <Space.Compact block>
              <Button
                type={view === 'image' ? 'primary' : 'default'}
                onClick={() => this.changeViewMode('image')}
              >
                <span><PictureOutlined /> <FormattedMessage id="app.images" defaultMessage="Images"/></span>
              </Button>
              <Button
                type={view === 'table' ? 'primary' : 'default'}
                onClick={() => this.changeViewMode('table')}
              >
                <span><BarsOutlined /> <FormattedMessage id="app.list" defaultMessage="List"/></span>
              </Button>
            </Space.Compact>
          </div>
        </Affix>
        <br />
        <div className="photos">
          {
            photos.map(photo => (
              <div className="photo" key={photo._id}>
                <div className="image">
                  <img
                    src={_get(photo, 'view.thumbnail.url', '')}
                    alt=""
                    onClick={() => this._multiSelect(photo._id)}
                  />
                  <Checkbox
                    onChange={() => this.onSelectPhoto(photo._id)}
                    checked={this.isSelectedPhoto(photo._id)}
                    style={{
                      float: 'right',
                      paddingTop: '2px',
                      paddingRight: '2px'
                    }}
                  />
                </div>
              </div>
            ))
          }
        </div>
        <Affix offsetBottom={0}>
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            pageSize={limit}
            total={photoCount}
            onChange={this.onPageChange}
            current={Number(page)}
            style={{
              background: 'rgba(255, 255, 255, 0.8)',
              padding: '6px',
              textAlign: 'center'
            }}
          />
        </Affix>
      </div>
      )
    } else {
      const columns = [
        {
          title: <FormattedMessage id="app.fileName" defaultMessage="File Name"/>,
          // dataIndex: ['fileName'],
          key: 'fileName',
          render: (text, photo) => (
            <a href={`/photos/${photo._id}/edit`}> { photo.fileName } </a>
          )
        },
        {
          title: <FormattedMessage id="app.reason" defaultMessage="Reason"/>,
          // dataIndex: ['size'],
          key: 'reason',
          render: (text, photo) => (
            <Tag>
              {_get(photo, 'reported')}
            </Tag>
          )
        },
        {
          title: <FormattedMessage id="app.dimensions" defaultMessage="Dimensions"/>,
          // dataIndex: ['size'],
          key: 'size',
          render: (text, photo) => (
            <Tag>
              {_get(photo, 'original.size.width')} x{' '}
              {_get(photo, 'original.size.height')}
            </Tag>
          )
        },
        {
          title: <FormattedMessage id="app.preview" defaultMessage="Preview"/>,
          key: 'preview',
          render: (text, photo) => (
            <Popover
              content={<img src={_get(photo, 'view.thumbnail.url', '')} alt="" />}
            >
              <Button><FormattedMessage id="app.preview" defaultMessage="Preview"/></Button>
            </Popover>
          )
        }
      ]
      return (
        <div>
          <Affix>
            <div style={{ float: 'right' }}>
              <Space.Compact block style={{ paddingRight: '10px' }}>
                <Button
                  type="submit"
                  disabled={this.state.selectedPhotos.length === 0}
                  onClick={this.handleDeletePhotos}
                >
                  <span><FormattedMessage id="app.deletePhotos" defaultMessage="Delete Photos"/> <DeleteOutlined /></span>
                </Button>
              </Space.Compact>
              <Space.Compact block>
                <Button
                  type={view === 'image' ? 'primary' : 'default'}
                  onClick={() => this.changeViewMode('image')}
                >
                  <span><PictureOutlined /> <FormattedMessage id="app.images" defaultMessage="Images"/></span>
                </Button>
                <Button
                  type={view === 'table' ? 'primary' : 'default'}
                  onClick={() => this.changeViewMode('table')}
                >
                  <span><BarsOutlined /> <FormattedMessage id="app.list" defaultMessage="List"/></span>
                </Button>
              </Space.Compact>
            </div>
          </Affix>
          <br />
          <div className="photos">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={photos.filter(p => !p.deleted)}
              rowKey={photo => photo._id}
              pagination={false}
            />
          </div>
          <Affix offsetBottom={0}>
            <Pagination
              showQuickJumper
              defaultCurrent={1}
              pageSize={limit}
              total={photoCount}
              onChange={this.onPageChange}
              current={Number(page)}
              style={{
                background: 'rgba(255, 255, 255, 0.8)',
                padding: '6px',
                textAlign: 'center'
              }}
            />
          </Affix>
        </div>
      )
    }

    // const { photos, photoCount } = this.props.data

    // const photoRows = _chunk(photos, 6)
    // console.log(this.props.data, photos)
    // console.log(photoRows)
  }
}

export default PhotoReportList
