import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import qbFindCustomersQuery from '~/graphql/queries/qbFindCustomers.gql'
import qbEventCustomerQuery from '~/graphql/queries/qbEventCustomer.gql'
import qbSetEventCustomerMutation from '~/graphql/mutations/qbSetEventCustomer.gql'
import { Select } from 'antd'
const QbCustomer = ({ eventId }) => {
  const [qbSetEventCustomer] = useMutation(qbSetEventCustomerMutation)
  const { error, loading, data } = useQuery(qbFindCustomersQuery)
  const { data: qbEventCustomerData } = useQuery(qbEventCustomerQuery, {
    variables: {
      eventId
    }
  })
  if (error) {
    return 'Error'
  }
  if (loading) {
    return 'Loading...'
  }
  // console.log({ eventId, qbEventCustomer })
  const value = qbEventCustomerData?.qbEventCustomer?.qbId
  // console.log({ qbEventCustomer })
  // const name = qbEventCustomer?.qbName
  const handleChange = async (qbId) => {
    console.log('change', qbId)
    const customer = data?.qbFindCustomers.find(c => c.value === qbId)
    const qbName = customer?.name
    await qbSetEventCustomer({ variables: {
      eventId,
      qbId,
      qbName
    }})
  }
  return <Select
    showSearch
    onChange={handleChange}
    defaultValue={value}
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    style={{ width: '100%' }}
  >
    {data?.qbFindCustomers.map(c =>
      <Select.Option key={c.value} value={c.value} >{c.name}</Select.Option>
    )}
  </Select>
}

export default QbCustomer
