import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
// import { graphql } from '@apollo/client/react/hoc'
import { Link } from 'react-router-dom'
import { Table, Button, Input, Radio, Form } from 'antd'
import { inject, observer } from 'mobx-react'
import _omitBy from 'lodash/omitBy'
import _isUndefined from 'lodash/isUndefined'
import adminUserListQuery from '~/graphql/queries/adminUserList.gql'
import AdminuserApproveButton from './AdminuserApproveButton'
import { compose } from 'recompose'
import { useQuery } from '@apollo/client'

const [
  SYMBOL_APPROVED,
  SYMBOL_REJECTED,
  SYMBOL_PENDING
] = ['✔', '✘', '⌛']

const reviewMap = {
  [SYMBOL_APPROVED]: true,
  [SYMBOL_REJECTED]: false,
  [SYMBOL_PENDING]: null
}

const UserList = compose(
  observer,
  inject('router')
)(({ router }) => {
  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue } = form
  const { location } = router
  const { p: page, s: search, role, verified: v, activated: a, r: reviewStatus} = location.query

  const verified = reviewMap[reviewStatus]
  const { error, loading, data } = useQuery(adminUserListQuery, {
    variables: {
      page: parseInt(page, 10) || 1,
      search,
      role,
      // verified: v && v === 'true',
      activated: a && a === 'true',
      verified
    },
  })

  useEffect(() => {
    setFieldsValue({ search })
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = () => {
    const { search } = getFieldsValue(true)
    const { p, ...query } = location.query
    location.query = {
      ...query,
      s: search,
    }
  }
  const handlePageChange = (page) => {
    location.query = {
      ...location.query,
      p: page,
    }
  }
  const handleTableChange = (pagination, filters, sorter) => {
    const { verified: [verified] = [], activated: [activated] = [], role: [role] = [] } = filters
    location.query = _omitBy({
      ...location.query,
      verified,
      activated,
      role,
    }, _isUndefined)
  }

  if (error) {
    return 'Error'
  }

  const setReview = (symbol) => {
    location.query = _omitBy({
      ...location.query,
      r: symbol
    }, _isUndefined)
  }

  const { adminUserList } = data || {}
  const { items: users, pageInfo } = adminUserList || {}
  const { itemCount, perPage } = pageInfo || {}
  const verifiedFilter = v ? [v === 'true'] : []
  const activatedFilter = a ? [a === 'true'] : []
  const roleFilter = role ? [role] : []
  const columns = [
    {
      title: 'Number',
      dataIndex: ['number'],
      key: 'number',
      render: (number) => number > 0 && `TR${`${number}`.padStart(4, 0)}`,
    },
    {
      title: <FormattedMessage id='app.username' defaultMessage='Username' />,
      dataIndex: ['username'],
      key: 'username',
    },
    {
      title: <FormattedMessage id='app.displayName' defaultMessage='Display Name' />,
      dataIndex: ['displayName'],
      key: 'displayName',
    },
    {
      title: <FormattedMessage id='app.userVerified' defaultMessage='Verified' />,
      dataIndex: ['verified'],
      key: 'verified',
      render: (verified) => JSON.stringify(verified),
      filterMultiple: false,
      filteredValue: verifiedFilter,
      defaultFilteredValue: [],
      filters: [
        {
          text: 'true',
          value: 'true',
        },
        {
          text: 'false',
          value: 'false',
        },
      ],
    },
    {
      title: <FormattedMessage id='app.activated' defaultMessage='Activated' />,
      dataIndex: ['activated'],
      key: 'activated',
      render: (activated) => JSON.stringify(activated),
      filterMultiple: false,
      filteredValue: activatedFilter,
      defaultFilteredValue: [],
      filters: [
        {
          text: 'true',
          value: 'true',
        },
        {
          text: 'false',
          value: 'false',
        },
      ],
    },
    {
      title: <FormattedMessage id='app.role' defaultMessage='Role' />,
      dataIndex: ['role'],
      key: 'role',
      filterMultiple: false,
      filteredValue: roleFilter,
      defaultFilteredValue: ['photographer'],
      filters: [
        {
          text: 'superadmin',
          value: 'superadmin',
        },
        {
          text: 'admin',
          value: 'admin',
        },
        {
          text: 'organizer',
          value: 'organizer',
        },
        {
          text: 'photographer',
          value: 'photographer',
        },
        {
          text: 'tagger',
          value: 'tagger',
        },
      ],
    },
    {
      title: <FormattedMessage id='app.action' defaultMessage='Action' />,
      key: 'action',
      render: (text, record) => (
        <div>
          <span>
            <Button>
              <Link to={`/users/${record._id}/edit`}>
                <FormattedMessage id='app.edit' defaultMessage='Edit' />
              </Link>
            </Button>
          </span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="app.approve" defaultMessage="Approve" />,
      dataIndex: ['_id'],
      key: '_id',
      render: (_id, item) => (<AdminuserApproveButton adminuser={item} />),
      filterMultiple: null,
      filteredValue: verifiedFilter,
      defaultFilteredValue: [],
      filterDropdown: (
      <div><Radio.Group value={reviewStatus}>
        <Radio.Button value={SYMBOL_PENDING} onClick={() => setReview(SYMBOL_PENDING)}><FormattedMessage id="app.pending" defaultMessage="Pending" /></Radio.Button>
        <Radio.Button value={SYMBOL_APPROVED} onClick={() => setReview(SYMBOL_APPROVED)}><FormattedMessage id="app.approved" defaultMessage="Approved" /></Radio.Button>
        <Radio.Button value={SYMBOL_REJECTED} onClick={() => setReview(SYMBOL_REJECTED)}><FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Radio.Button>
        <Radio.Button value={undefined} onClick={() => setReview()}><FormattedMessage id="app.all" defaultMessage="All" /></Radio.Button>
          </Radio.Group></div>),
      
    }
    
  ]

  return (
    <div>
      <Form form={form}>
        <Form.Item name={['search']} initialValue={search}>
          <Input.Search enterButton onSearch={handleSearch} style={{ width: 240, margin: '1em' }} />
        </Form.Item>
      </Form>
      <div style={{ marginTop: 8 }}>
        <Table
          loading={loading}
          dataSource={users}
          columns={columns}
          rowKey='_id'
          onChange={handleTableChange}
          scroll={{ x: true }}
          pagination={{
            pageSize: perPage,
            current: page && parseInt(page, 10),
            showQuickJumper: true,
            total: itemCount,
            onChange: handlePageChange,
          }} 
        />
           {/* <Table.Column
              title={<FormattedMessage id="app.approve" defaultMessage="Approve" />}
              key='verified'
              dataIndex={['_id']}
              filterDropdown={<Radio.Group value={reviewStatus}>
                <Radio.Button value={SYMBOL_PENDING} onClick={() => setReview(SYMBOL_PENDING)}><FormattedMessage id="app.pending" defaultMessage="Pending" /></Radio.Button>
                <Radio.Button value={SYMBOL_APPROVED} onClick={() => setReview(SYMBOL_APPROVED)}><FormattedMessage id="app.approved" defaultMessage="Approved" /></Radio.Button>
                <Radio.Button value={SYMBOL_REJECTED} onClick={() => setReview(SYMBOL_REJECTED)}><FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Radio.Button>
                <Radio.Button value={undefined} onClick={() => setReview()}><FormattedMessage id="app.all" defaultMessage="All" /></Radio.Button>
              </Radio.Group>}
              render={(_id, item) => <AdminuserApproveButton adminuser={item} />}
            />
            </Table> */}

      </div>
    </div>
  )
})

export default UserList
