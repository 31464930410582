import React from 'react'
import { injectIntl } from 'react-intl'
import { Table, Button, Input, Row, Col } from 'antd'
// import moment from 'moment'
// import _orderBy from 'lodash/orderBy'
// import { observer } from 'mobx-react'
// import organizerListQuery from '~/graphql/queries/organizerList.gql'
import organizerListQuery from '~/graphql/queries/adminUserList.gql'
// import deletePartnerMutation from '~/graphql/mutations/deleteOrganizer.gql'
// import { useMutation, useQuery } from '@apollo/client'
import { useQuery } from '@apollo/client'
// import { useHistory } from 'react-router-dom'
import { inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'
import { compose } from 'recompose'

const Partners = compose(
  inject('router'),
  injectIntl
)(({ router, intl }) => {
  const { location } = router
  const { p: page, s: search } = location.query
  const { error, loading, data } = useQuery(organizerListQuery, {
    variables: {
      search: search || '',
      page: parseInt(page, 10),
      role: 'organizer'
    }
  })
  // const [deletePartner] = useMutation(deletePartnerMutation)
  const {
    currentPage,
    itemCount,
    perPage
  } = data?.adminUserList?.pageInfo ?? {}
  const handleSearch = (value) => {
    location.query = _omitBy({
      ...location.query,
      s: value,
      p: undefined,
    }, _isEmpty)
  }
  // const handleDeletePartner = _id => {
  //   deletePartner({
  //     variables: {
  //       _id
  //     },
  //     refetchQueries: [{ query: organizerListQuery }]
  //   }).then(({ data: { deleteOrganizer: { record: { name } } } }) => {
  //     message.info(`Partner "${name}" deleted`, 5)
  //   })
  // }
  const handlePagination = (page) => {
    location.query = _omitBy({
      ...location.query,
      p: `${page}`
    }, _isEmpty)
  }
  if (error) {
    return 'Error'
  }
  return (<>
    <Row gutter={[6, 12]} type="flex" justify="space-between">
      <Col xs={24} sm={{ span: 16 }} lg={{ span: 8 }}>
        <Input.Search
          enterButton
          allowClear
          onSearch={handleSearch}
        />
      </Col>
      {/* <Col xs={24} sm={{ span: 8 }} lg={{ span: 4 }}>
        <Link to="/partners/add">
          <span><Button block icon={<PlusOutlined />} type="primary" > {intl.formatMessage({ id: 'app.addPartner', defaultMessage: 'Add Partner' })}</Button></span>
        </Link>
      </Col> */}
    </Row>
    <Row gutter={[6, 12]}>
      <Col span={24}>
        <Table
          loading={loading}
          dataSource={data?.adminUserList?.items}
          rowKey={(record) => record._id}
          pagination={{
            pageSize: perPage,
            current: currentPage,
            total: itemCount,
            onChange: handlePagination
          }}
        >
          {/* <Table.Column
            title='No.'
            dataIndex={['number']}
            key='number'
            render={(text, record, index) => index + 1}
          /> */}
          <Table.Column
            title={intl.formatMessage({ id: 'app.partner', defaultMessage: 'Partner' })}
            dataIndex={['displayName']}
            key='displayName'
            render={(name, { _id }) => <Link to={`/events:balance:organizer/${_id}`}>{name}</Link>}
          />
          <Table.Column
            title={<>รายรับ Partner<br/>ค้างโอน (บาท)</>}
            dataIndex={['organizerData', 'financial', 'balance']}
            key='organizerData.financial.balance'
            render={balance => balance?.toFixed(2) || 0}
          />
          <Table.Column
            title={intl.formatMessage({ id: 'app.partnerShare', defaultMessage: 'Partner Share' })}
            dataIndex={['organizerData', 'organizerShare']}
            key='organizerData.organizerShare'
            render={(text, { organizerData }) =>
              `${text || 0} ${organizerData?.organizerShareUnit === 'fixed' ? intl.formatMessage({ id: 'app.baht', defaultMessage: 'Baht' }) : '%'}`}
          />
          <Table.Column
            title={intl.formatMessage({ id: 'app.action', defaultMessage: 'Action' })}
            dataIndex={['_id']}
            key='action'
            render={(_id) => <div>
              <Link to={`/users/${_id}/edit`} >
                <Button type="link">{intl.formatMessage({ id: 'app.edit', defaultMessage: 'Edit' })}</Button>
              </Link>
              {/* <Button type="link" onClick={() => handleDeletePartner(_id)}>ลบ</Button> */}
            </div>}
          />
        </Table>
      </Col>
    </Row>
  </>)
})

export default Partners
