import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Select, Divider } from 'antd'
import eventsQuery from '../graphql/queries/events.gql'

const EventsSelect = ({ mode = 'default', onChange, value, variables: { organizers, officialPartner, photoFileDeletedAt } = {}, selectAll }) => {
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(eventsQuery, {
    variables: {
      search,
      officialPartner,
      organizerIds: organizers?.length ? { in: organizers } : undefined,
      photoFileDeletedAt: photoFileDeletedAt ? { gte: photoFileDeletedAt } : undefined,
      limit: 999999,
    },
  })
  if (error) {
    return 'Error'
  }
  const handleSelectAll = () => {
    const eventIds = data?.events.map((item) => item._id)
    onChange(eventIds)
  }
  if (mode === 'multiple') {
    return (
      <Select
        mode='multiple'
        loading={loading}
        allowClear
        showSearch
        filterOption={false}
        placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}
        onChange={onChange}
        onSearch={(v) => setSearch(v)}
        onBlur={() => setSearch()}
        value={value}
        maxTagCount={6}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {selectAll && (
              <div>
                <Divider style={{ margin: '4px 8px' }} />
                <div onMouseDown={(e) => e.preventDefault()} onClick={handleSelectAll} style={{ padding: '4px 8px', cursor: 'pointer' }}>
                  <FormattedMessage id='app.allEvents' defaultMessage='All Events' />
                  {` (${data?.events?.length ?? 0})`}
                </div>
              </div>
            )}
          </div>
        )}
      >
        {data?.events?.map((item) => (
          <Select.Option key={item._id} value={item._id}>
            {item.title}
          </Select.Option>
        ))}
      </Select>
    )
  }
}

export default EventsSelect
