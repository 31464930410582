import React, { useState } from 'react'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Form, Input, InputNumber, Spin } from 'antd'
import { compose } from 'recompose'
// import _min from 'lodash/min'
import adminUserFinancialQuery from '~/graphql/queries/adminUserFinancial.gql'
import createTransferRequestMutation from '~/graphql/mutations/createTransferRequest.gql'
import AdminUserSelect from './AdminUserSelect'
import Toolbar from './Toolbar'

const FEE = 30.0
const MINIMUM_AMOUNT = 500.0
const MAXIMUM_AUTO_APPROVED_AMOUNT = 50000

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}

const TransferRequestCreate = compose(
  inject('user'),
  inject('router'),
)(({ router, user }) => {
  const [form] = Form.useForm()
  const { getFieldValue, resetFields } = form
  const { location } = router
  const [submitting, setSubmitting] = useState(false)
  const { userId: _userId } = location.query
  const authorized = ['superadmin'].includes(user.role)
  // TODO: better ACL
  // TODO: handle non-authorized
  const userId = ['photographer'].includes(user.role) ? user._id : authorized && _userId
  const { error, loading, data } = useQuery(adminUserFinancialQuery, {
    skip: !userId,
    variables: { _id: userId },
    fetchPolicy: 'network-only',
  })
  const [createTransferRequest] = useMutation(createTransferRequestMutation)
  const { adminUser } = data || {}
  const maxAmount = ['superadmin'].includes(user.role) 
    ? adminUser?.balance?.total
    : Math.min(adminUser?.balance?.total, MAXIMUM_AUTO_APPROVED_AMOUNT)
  const handleAdminUserSelect = (userId) => {
    location.query = {
      ...location.query,
      userId,
    }
  }
  const handleSubmit = async (values) => {
      setSubmitting(true)
      const {
        record: { amount, note },
      } = values
      try {
        await createTransferRequest({
          variables: {
            userId,
            amount,
            note,
          },
        })
        resetFields()
        setSubmitting(false)
        router.push('/transfers')
      } catch (err) {
        setSubmitting(false)
        console.error(err)
      }
    // TODO: redirect to /transfers
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  if (error) {
    return 'Error'
  }
  return (
    <div>
      <Toolbar
        left={
          <div>{authorized && <AdminUserSelect onChange={handleAdminUserSelect} value={userId} role={'photographer'} style={{ width: 200 }} />}</div>
        }
      />
      {(!userId && authorized) ? (
        <div>
          <FormattedMessage id='app.selectUser' defaultMessage='Please Select User!' />
        </div>
      ) : !adminUser?.recipientId ? (
        <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
          <FormattedMessage id='app.notice.noBankAccount' defaultMessage='No bank account linked, please link bank account' />
          &nbsp;
          <Link to='/profile/edit'>
            <FormattedMessage id='app.action.editAccount' defaultMessage='Edit Account' />
          </Link>
        </div>
      ) : !adminUser?.recipient?.active || !adminUser?.recipient?.verified ? (
        <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
          <FormattedMessage
            id='app.notice.recipientNotActivated'
            defaultMessage='Your bank account is not yet verified or activated, please wait around 1 hour, if your account is still not verified after 1 hour, please contact admin@thai.run'
          />
        </div>
      ) : adminUser?.incompleteTransfers?.length > 0 ? (
        <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
          <FormattedMessage
            id='app.notice.incompleteTransfer'
            defaultMessage='You have an incomplete transfer request (pending approval or pending transfer), please wait wait until the request is reviewed and completed before creating a new withdrawal request.'
          />
        </div>
      ) : adminUser?.balance?.total < MINIMUM_AMOUNT ? (
        <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
          <FormattedMessage
            id='app.notice.insufficientBalance'
            defaultMessage='Your balance is {amount} THB, you must have at least {minimumAmount} THB in order to create a withdrawal request.'
            values={{
              amount: <FormattedNumber value={adminUser.balance.total} minimumFractionDigits={2} />,
              minimumAmount: MINIMUM_AMOUNT,
            }}
          />
        </div>
      ) : (
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item {...formItemLayout} label={<FormattedMessage id='app.myBalance' defaultMessage='My Balance' />}>
            <FormattedNumber value={adminUser.balance.total} minimumFractionDigits={2} />
          </Form.Item>
          <Form.Item {...formItemLayout} label={<FormattedMessage id='app.accountNumber' defaultMessage='My Balance' />}>
            {adminUser?.bankAccount?.number ?? ''}
          </Form.Item>
          <Form.Item {...formItemLayout} label={<FormattedMessage id='app.accountName' defaultMessage='My Balance' />}>
            {adminUser?.bankAccount?.name ?? ''}
          </Form.Item>
          <Form.Item {...formItemLayout} name={['record', 'amount']} label={<FormattedMessage id='app.amountRequested' defaultMessage='Amount' />} initialValue={maxAmount}>
            <InputNumber precision={2} min={MINIMUM_AMOUNT} max={maxAmount} />
          </Form.Item>
          <Form.Item {...formItemLayout} label={<FormattedMessage id='app.deductTransferFee' defaultMessage='Deduct Transfer Fee' />}>
            <FormattedNumber value={-FEE} minimumFractionDigits={2} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => <Form.Item {...formItemLayout} label={<FormattedMessage id='app.amountToReceive' defaultMessage='Amount to be received' tagName='strong' />}>
              <strong>
                <FormattedNumber value={getFieldValue(['record', 'amount']) - FEE} minimumFractionDigits={2} />
              </strong>
            </Form.Item>}
          </Form.Item>
          <Form.Item {...formItemLayout} name={['record', 'note']} label={<FormattedMessage id='app.note' defaultMessage='Note' />}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 18, offset: 3 } }} style={{ whiteSpace: 'pre-line' }}>
            <FormattedMessage
              id='app.remark.transferRequest'
              values={{
                commissionRate: adminUser.commissionRate * 100,
                minimumAmount: MINIMUM_AMOUNT,
                transferFee: FEE,
                maximumAutoApprovedAmount: <FormattedNumber value={MAXIMUM_AUTO_APPROVED_AMOUNT} />,
              }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 8, offset: 8 } }}>
            <Button type='primary' htmlType='submit' loading={submitting}>
              <FormattedMessage id='app.submitRequest' defaultMessage='Submit Request' />
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  )
})

export default TransferRequestCreate
