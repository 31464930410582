import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Modal, Alert } from 'antd'
import moment from 'moment'
import eventQuery from '~/graphql/queries/event.gql'
import currentAdminUserQuery from '~/graphql/queries/currentAdminUser.gql'
import removeApprovedMutation from '~/graphql/mutations/removeApproved.gql'

const EventCancelButton = ({ user, event, registrable, agreementAccept, disabled }) => {
  const history = useHistory()
  const { data } = useQuery(currentAdminUserQuery)
  const currentUserId = data?.currentAdminUser?._id

  const [removeApproved] = useMutation(removeApprovedMutation, {
    variables: {
      eventId: event._id,
      photographerId: user._id,
    },
    refetchQueries: [{ query: eventQuery }],
  })

  if (['photographer', 'admin'].includes(user.role) && currentUserId !== user._id) {
    return <Alert message='Error' description='ไม่สามารถยกเลิกงานวิ่งได้' type='error' showIcon />
  }
  const handleClick = () => {
    Modal.confirm({
      title: 'Remove Approved',
      content: `confirm ${event.title} removal`,
      async onOk() {
        await removeApproved()
        history.push(`/events`)
      },
      closable: false,
    })
  }
  return (
    <>
      {['photographer', 'admin'].includes(user.role) && currentUserId === user._id && (
        <div style={{ textAlign: 'center' }}>
          <Button type='primary' onClick={handleClick} disabled={!registrable || !agreementAccept || disabled} danger>
            <FormattedMessage id='app.cancelEvent' defaultMessage='Cancel Event' />
          </Button>
          {event.registrationMethod === 'free' ? (
            <p style={{ textAlign: 'center' }}>สามารถยกเลิกการลงทะเบียนได้ถึงวันที่ {moment(event.startDate).add(-1, 'days').format('ll')}</p>
          ) : (
            <p style={{ textAlign: 'center' }}>
              สามารถยกเลิกการลงทะเบียนได้ถึงวันที่ {moment(event.registrationEndsAt).add(-48, 'hours').format('ll')}
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default EventCancelButton
