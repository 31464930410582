import logoThairun from './assets/images/logo.png'
import iconRunX from './assets/images/logo-small.png'
import logoMenu from './assets/images/white_logo.png'
const platforms = [{
  platform: 'thai',
  domains: ['admin.photo.thai.run', 'admin.photo.phuket.run', 'pro.photo.thai.run', 'pro.photo.phuket.run', 'admin.photo.thai.local'],
  logo: logoThairun,
  title: 'Photo ThaiRun Admin',
  logo_menu: logoMenu
}, {
  platform: 'malay',
  domains: ['admin.photo.harimau.run', 'staging.admin.harimau.run', 'pro.photo.harimau.run', 'staging.pro.harimau.run', 'admin.photo.malay.local'],
  logo: iconRunX,
  title: 'Photo Platform Harimau.Run',
  logo_menu: iconRunX
}, {
  platform: 'viet',
  domains: ['admin.photo.viet.run', 'staging.admin.viet.run', 'pro.photo.viet.run', 'staging.pro.viet.run', 'admin.photo.viet.local'],
  logo: iconRunX,
  title: 'Photo Platform Viet.Run',
  logo_menu: iconRunX
}, {
  platform: 'malay',
  domains: ['admin.photo.indo.run', 'staging.admin.indo.run', 'pro.photo.indo.run', 'staging.pro.indo.run', 'admin.photo.indo.local'],
  logo: iconRunX,
  title: 'Photo Platform Indo.Run',
  logo_menu: iconRunX
}]

export default platforms.find(p => p.domains.some(domain => window.location.origin.includes(domain)))
