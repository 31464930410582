import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import { useQuery, useMutation } from '@apollo/client'
import compose from 'recompose/compose'
import { message, Button, Form, Input, InputNumber, Upload, Select, Switch, Tooltip, Tag, Row, Col, Spin } from 'antd'
import { HomeOutlined, InfoCircleOutlined, LockOutlined, MailOutlined, PhoneOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons'
// import BankProfile from './BankProfile'
// import BankProfileDetail from './BankProfileDetail'
import adminUserQuery from '~/graphql/queries/adminUser.gql'
import updateAdminUserMutation from '~/graphql/mutations/updateAdminUser.gql'
import registerAdminUserMutation from '~/graphql/mutations/registerAdminUser.gql'
import CloudImageUploader from '~/components/CloudImageUploader'
import province from '~/assets/jsons/changwats'
import supportedBankList from '~/data/supportedBanks.json'

const fileReader = new FileReader() // not available on server
const fileData = (url = '') => {
  const matches = url.match(/^data:(.+);base64,(.*)$/)
  if (!matches) {
    return null
  }
  const [, mimeType, base64data] = matches
  return {
    mimeType,
    base64data,
  }
}
const columnQuarterLayout = {
  xs: { span: 24 },
  lg: { span: 11 },
}
const columnHalfLayout = {
  xs: { span: 24 },
  sm: { span: 8 },
}
const columnFullLayout = {
  xs: { span: 24 },
  sm: { span: 20 },
}
const styleSection = {
  fontSize: '1rem',
  margin: '1rem 0',
  borderBottom: '1px solid rgb(232, 232, 232)',
}

const EditUser = compose(
  inject('user'),
  inject('router'),
  observer,
  injectIntl
)(({ intl, router, user: currentUser, userId }) => {
  const [form] = Form.useForm()
  const { getFieldsValue, getFieldValue, setFieldsValue } = form
  const [linkBankAccount, setLinkBankAccount] = useState(false)
  const [updateAdminUser] = useMutation(updateAdminUserMutation)
  const [registerAdminUser] = useMutation(registerAdminUserMutation)

  const {
    error,
    loading,
    data = {},
  } = useQuery(adminUserQuery, {
    skip: !userId,
    variables: { id: userId },
    fetchPolicy: 'network-only',
  })

  if (error) {
    return message.error(error)
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }

  const isAllow = ['superadmin', 'admin', 'accounting'].includes(currentUser.role)
  const { adminUser: user } = data
  const role = currentUser?.role

  const beforeUpload = (name) =>
    function (file, files) {
      // const isPNG = file.type === 'image/png'
      // if (!isPNG) {
      //   message.error(`กรุณาอัปโหลดไฟล์ ${acceptLogoExtension} เท่านั้น`)
      // } else {
      fileReader.onload = (e) => {
        file.thumbUrl = fileReader.result
        setFieldsValue({
          [name]: file,
        })
      }
      fileReader.readAsDataURL(file)
      // }
      return false
    }
  const profileFileProps = {
    name: 'profileFile',
    accept: 'image/*',
    action: '',
    beforeUpload: beforeUpload('profileFile'),
    // defaultFileList: getFieldValue('profileFile') ? [getFieldValue('profileFile')] : [],
    fileList: getFieldValue('profileFile') ? [getFieldValue('profileFile')] : [],
    // listType: 'picture',
    showUploadList: false,
    // className: 'profile-picture'
  }
  const logoFileProps = {
    name: 'logoFile',
    accept: '.png',
    action: '',
    beforeUpload: beforeUpload('logoFile'),
    // defaultFileList: getFieldValue('logoFile') ? [getFieldValue('logoFile')] : [],
    fileList: getFieldValue('logoFile') ? [getFieldValue('logoFile')] : [],
    // listType: 'picture',
    showUploadList: false,
    // className: 'profile-picture'
  }
  const now = Date.now()

  if (!['superadmin', 'admin'].includes(role) && currentUser._id !== user?._id) {
    return <div>Access denied</div>
  }
  if (!user && userId) {
    return <div>User not found</div>
  }

  const handleLinkBankAccount = () => setLinkBankAccount(true)
  const handleSubmit = async () => {
    const values = getFieldsValue(true)
    const {
      record: { confirmPassword, number, ...record },
      profileFile,
      logoFile,
      organizerData,
      brokerData,
    } = values
    const profileUrl = profileFile?.thumbUrl
    const logoUrl = logoFile?.thumbUrl

    let newRecord
    if (number) {
      newRecord = {
        number,
        ...record,
      }
    } else {
      newRecord = {
        ...record,
      }
    }
    if (userId) {
      try {
        await updateAdminUser({
          variables: {
            _id: user._id,
            record: newRecord,
            profile: fileData(profileUrl),
            logo: fileData(logoUrl),
            organizerData,
            brokerData,
          },
        })
        message.info(`Successfully Edit Profile `)
        router.push('/profile')
      } catch (err) {
        err.graphQLErrors.forEach((error) => message.error(error.message))
      }
    } else {
      try {
        const {
          data: {
            registerAdminUser: {
              record: { displayName },
            },
          },
        } = await registerAdminUser({
          variables: {
            record,
            profile: fileData(profileUrl),
            logo: fileData(logoUrl),
            organizerData,
            brokerData,
          },
        })
        message.info(`Successfully registered ${displayName}`)
        router.push('/users')
      } catch (err) {
        err.graphQLErrors.forEach((error) => message.error(intl.formatMessage({ id: `api.errors.${error.message}`, defaultMessage: 'Error' })))
      }
    }
  }
  const checkConfirm = (rule, value, callback) => {
    if (!value || getFieldValue(['record', 'password']) === value) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('Two passwords that you enter is inconsistent!'))
  }
  const handleRequiredMessage = ({ id, msg }) =>
    `${intl.formatMessage(
      { id: 'app.fieldRequired', defaultMessage: '{field} is required' },
      { field: intl.formatMessage({ id: `app.${id}`, defaultMessage: msg }) }
    )}`

  return (
    <Form form={form} onFinish={handleSubmit} scrollToFirstError={{ behavior: 'smooth' }}>
      <Row
        type='flex'
        justify='end'
        align='middle'
        style={{
          fontSize: '1rem',
          padding: '0.5rem 3rem',
        }}
      >
        <Col>
          <Button type='primary' htmlType='submit' icon={<SaveOutlined />}>
            {intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' })}
          </Button>
        </Col>
      </Row>

      <Row type='flex' justify='space-around'>
        <Col {...columnHalfLayout}>
          <div style={styleSection}>{intl.formatMessage({ id: 'app.account', defaultMessage: 'Account' })}</div>
          <Row type='flex' justify='space-between'>
            <Col {...columnQuarterLayout} lg={!['superadmin', 'admin'].includes(role) ? 24 : 11}>
              <Form.Item
                name={['record', 'username']}
                label={intl.formatMessage({
                  id: 'app.username',
                  defaultMessage: 'Username',
                })}
                initialValue={user?.username}
                rules={[{ required: true, message: handleRequiredMessage({ id: 'username', msg: 'Username' }) }]}
              >
                <Input
                  disabled={Boolean(userId) && !isAllow}
                  placeholder={intl.formatMessage({
                    id: 'app.username',
                    defaultMessage: 'Username',
                  })}
                />
              </Form.Item>
            </Col>
            {['superadmin', 'admin'].includes(role) && (
              <Col {...columnQuarterLayout}>
                <Form.Item
                  name={['record', 'role']}
                  label={intl.formatMessage({
                    id: 'app.role',
                    defaultMessage: 'Role',
                  })}
                  initialValue={data?.adminUser?.role}
                  rules={[{ required: true, message: handleRequiredMessage({ id: 'role', msg: 'Role' }) }]}
                >
                  <Select
                    disabled={Boolean(userId) && !['superadmin', 'admin'].includes(role)}
                    placeholder={intl.formatMessage({
                      id: 'app.select',
                      defaultMessage: 'Please Select...',
                    })}
                  >
                    {[
                      ...(['superadmin'].includes(role) 
                        ? ['superadmin', 'admin', 'accounting', 'customer', 'customerService', 'tagger'] 
                        : []
                      ),
                      'broker', 'organizer', 'photographer'
                    ].map(
                      (el, i) => (
                        <Select.Option key={i + 1} value={el}>
                          {el}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item
            name={['record', 'profile', 'nationalId']}
            label={intl.formatMessage({
              id: 'app.citizenID',
              defaultMessage: 'Citizen ID',
            })}
            initialValue={user?.profile?.nationalId}
            rules={[{ required: true, message: handleRequiredMessage({ id: 'citizenID', msg: 'Citizen ID' }) }]}
          >
            <Input
              disabled={!isAllow && user.verified}
              placeholder={intl.formatMessage({
                id: 'app.citizenID2',
                defaultMessage: 'เลขประจำตัวประชาขน ใช้สำหรับเบิกค่าภาพถ่าย',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'firstName']}
            label={intl.formatMessage({
              id: 'app.firstName',
              defaultMessage: 'First Name',
            })}
            initialValue={user?.profile?.firstName}
            rules={[{ required: true, message: handleRequiredMessage({ id: 'firstName', msg: 'First Name' }) }]}
          >
            <Input
              disabled={!isAllow && user.verified}
              placeholder={intl.formatMessage({
                id: 'app.firstName',
                defaultMessage: 'First Name',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'lastName']}
            label={intl.formatMessage({
              id: 'app.lastName',
              defaultMessage: 'Last Name',
            })}
            initialValue={user?.profile?.lastName}
            rules={[{ required: true, message: handleRequiredMessage({ id: 'lastName', msg: 'Last Name' }) }]}
          >
            <Input
              disabled={!isAllow && user.verified}
              placeholder={intl.formatMessage({
                id: 'app.lastName',
                defaultMessage: 'Last Name',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'displayName']}
            label={intl.formatMessage({
              id: 'app.displayName',
              defaultMessage: 'Display Name',
            })}
            initialValue={user?.displayName}
            rules={[{ required: true, message: handleRequiredMessage({ id: 'displayName', msg: 'Display Name' }) }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'app.displayName',
                defaultMessage: 'Display Name',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'email']}
            label={intl.formatMessage({
              id: 'app.email',
              defaultMessage: 'Email',
            })}
            initialValue={user?.profile?.email}
            rules={[{ required: true, message: handleRequiredMessage({ id: 'email', msg: 'Email' }) }]}
          >
            <Input
              prefix={<MailOutlined style={{ fontSize: 13 }} />}
              placeholder={intl.formatMessage({
                id: 'app.email',
                defaultMessage: 'Email',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'phone']}
            label={intl.formatMessage({
              id: 'app.phone',
              defaultMessage: 'Phone',
            })}
            initialValue={user?.profile?.phone}
            rules={[{ required: true, message: handleRequiredMessage({ id: 'phone', msg: 'Phone' }) }]}
          >
            <Input
              prefix={<PhoneOutlined style={{ fontSize: 13 }} />}
              placeholder={intl.formatMessage({
                id: 'app.phone',
                defaultMessage: 'Phone',
              })}
            />
          </Form.Item>
        </Col>

        <Col {...columnHalfLayout}>
          <div style={styleSection}>{intl.formatMessage({ id: 'app.residence', defaultMessage: 'Address' })}</div>
          <Form.Item
            name={['record', 'profile', 'address']}
            label={intl.formatMessage({
              id: 'app.address',
              defaultMessage: 'Address',
            })}
            initialValue={user?.profile?.address}
            // rules={[{ required: true, message: handleRequiredMessage({ id: 'address', msg: 'Address' }) }]}
          >
            <Input
              prefix={<HomeOutlined style={{ fontSize: 13 }} />}
              placeholder={intl.formatMessage({
                id: 'app.address',
                defaultMessage: 'Address',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'road']}
            label={intl.formatMessage({
              id: 'app.road',
              defaultMessage: 'Road',
            })}
            initialValue={user?.profile?.road}
            // rules={[{ required: true, message: handleRequiredMessage({ id: 'road', msg: 'Road' }) }]}
          >
            <Input
              prefix={<HomeOutlined style={{ fontSize: 13 }} />}
              placeholder={intl.formatMessage({
                id: 'app.road',
                defaultMessage: 'Road',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'moo']}
            label={intl.formatMessage({
              id: 'app.moo',
              defaultMessage: 'Moo/Building/Floor',
            })}
            initialValue={user?.profile?.moo}
            // rules={[{ required: true, message: handleRequiredMessage({ id: 'moo', msg: 'Moo/Building/Floor' }) }]}
          >
            <Input
              prefix={<HomeOutlined style={{ fontSize: 13 }} />}
              placeholder={intl.formatMessage({
                id: 'app.moo',
                defaultMessage: 'Moo/Building/Floor',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'subdistrict']}
            label={intl.formatMessage({
              id: 'app.subdistrict',
              defaultMessage: 'Subdistrict',
            })}
            initialValue={user?.profile?.subdistrict}
            // rules={[{ required: true, message: handleRequiredMessage({ id: 'subdistrict', msg: 'Subdistrict' }) }]}
          >
            <Input
              prefix={<HomeOutlined style={{ fontSize: 13 }} />}
              placeholder={intl.formatMessage({
                id: 'app.subdistrict',
                defaultMessage: 'Subdistrict',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'district']}
            label={intl.formatMessage({
              id: 'app.district',
              defaultMessage: 'District',
            })}
            initialValue={user?.profile?.district}
            // rules={[{ required: true, message: handleRequiredMessage({ id: 'district', msg: 'District' }) }]}
          >
            <Input
              prefix={<HomeOutlined style={{ fontSize: 13 }} />}
              placeholder={intl.formatMessage({
                id: 'app.district',
                defaultMessage: 'District',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'province']}
            label={intl.formatMessage({
              id: 'app.province',
              defaultMessage: 'Province',
            })}
            initialValue={user?.profile?.province}
            rules={[{ required: true, message: handleRequiredMessage({ id: 'province', msg: 'Province' }) }]}
          >
            <Select style={{ width: 200 }}>
              {Object.values(province).map((item) => (
                <Select.Option key={item.name.en} value={item.name.th}>
                  {item.name.th}
                </Select.Option>
              ))}
            </Select>
            {/* <Input
                  prefix={<HomeOutlined style={{ fontSize: 13 }} />}
                  placeholder={intl.formatMessage({
                    id: 'app.province',
                    defaultMessage: 'Province',
                  })}
                /> */}
          </Form.Item>
          <Form.Item
            name={['record', 'profile', 'postalCode']}
            label={intl.formatMessage({
              id: 'app.postalCode',
              defaultMessage: 'Postal Code',
            })}
            initialValue={user?.profile?.postalCode}
            rules={[{ required: true, message: handleRequiredMessage({ id: 'postalCode', msg: 'Postal Code' }) }]}
          >
            <Input
              prefix={<HomeOutlined style={{ fontSize: 13 }} />}
              placeholder={intl.formatMessage({
                id: 'app.postalCode',
                defaultMessage: 'Postal Code',
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item noStyle shouldUpdate>
        {() =>
          getFieldValue(['record', 'role']) === 'photographer' ? (
            <>
              <Row type='flex' justify='center'>
                <Col {...columnFullLayout}>
                  <div style={styleSection}>{intl.formatMessage({ id: 'app.photographer', defaultMessage: 'Photographer' })}</div>
                </Col>
              </Row>
              <Row type='flex' justify='space-around'>
                <Col {...columnHalfLayout}>
                  <Form.Item
                    name={['record', 'number']}
                    label={intl.formatMessage({
                      id: 'app.photographerIdNumber',
                      defaultMessage: 'Photographer Number',
                    })}
                    initialValue={user?.number}
                    normalize={(v) => v && Number.parseInt(v, 10)}
                  >
                    {isAllow ? (
                      <Input
                        disabled={!isAllow && user.verified}
                        type='text'
                        inputMode='numeric'
                        pattern='[0-9]*'
                        placeholder={intl.formatMessage({
                          id: 'app.photographerIdNumber',
                          defaultMessage: 'Photographer Number',
                        })}
                      />
                    ) : role === 'photographer' ? (
                      user.number && <Tag color='blue'>TR{`${user.number}`.padStart(4, 0)}</Tag>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col {...columnHalfLayout}>
                  <Form.Item
                    name={['record', 'verified']}
                    label={intl.formatMessage({
                      id: 'app.userVerified',
                      defaultMessage: 'Verified',
                    })}
                    initialValue={user?.verified}
                    valuePropName={'checked'}
                  >
                    <Switch disabled={!isAllow} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : getFieldValue(['record', 'role']) === 'organizer' ? (
            <>
              <Row type='flex' justify='center'>
                <Col {...columnFullLayout}>
                  <div style={styleSection}>{intl.formatMessage({ id: 'app.organizer', defaultMessage: 'Organizer' })}</div>
                </Col>
              </Row>
              <Row type='flex' justify='space-around'>
                <Col {...columnHalfLayout}>
                  <Form.Item
                    name={['organizerData', 'formalName']}
                    label={intl.formatMessage({
                      id: 'app.formalName',
                      defaultMessage: 'Formal Name',
                    })}
                    initialValue={user?.organizerData?.formalName}
                    rules={[{ required: true, message: handleRequiredMessage({ id: 'formalName', msg: 'Formal Name' }) }]}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'app.formalName',
                        defaultMessage: 'Formal Name',
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name={['organizerData', 'companyName']}
                    label={intl.formatMessage({
                      id: 'app.companyName',
                      defaultMessage: 'Company Name',
                    })}
                    initialValue={user?.organizerData?.companyName}
                    rules={[{ required: true, message: handleRequiredMessage({ id: 'companyName', msg: 'Company Name' }) }]}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'app.companyName',
                        defaultMessage: 'Company Name',
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col {...columnHalfLayout}>
                  <Form.Item
                    name={['organizerData', 'organizerShare']}
                    label={intl.formatMessage({
                      id: 'app.organizerShare',
                      defaultMessage: 'Organizer Share',
                    })}
                    initialValue={user?.organizerData?.organizerShare}
                    rules={[{ required: true, message: handleRequiredMessage({ id: 'organizerShare', msg: 'Organizer Share' }) }]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    name={['organizerData', 'organizerShareUnit']}
                    label={intl.formatMessage({
                      id: 'app.organizerShareUnit',
                      defaultMessage: 'Organizer Share Unit',
                    })}
                    initialValue={user?.organizerData?.organizerShareUnit}
                    rules={[{ required: true, message: handleRequiredMessage({ id: 'organizerShareUnit', msg: 'Organizer Share Unit' }) }]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({
                        id: 'app.select',
                        defaultMessage: 'Pleash Select...',
                      })}
                    >
                      <Select.Option value='percentage'>Percentage</Select.Option>
                      <Select.Option value='fixed'>Fixed</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : getFieldValue(['record', 'role']) === 'broker' ? (
            <>
              <Row type='flex' justify='center'>
                <Col {...columnFullLayout}>
                  <div style={styleSection}>{intl.formatMessage({ id: 'app.broker', defaultMessage: 'Broker' })}</div>
                </Col>
              </Row>
              <Row type='flex' justify='space-around'>
                <Col {...columnHalfLayout}>
                  <Form.Item
                    name={['brokerData', 'formalName']}
                    label={intl.formatMessage({
                      id: 'app.formalName',
                      defaultMessage: 'Formal Name',
                    })}
                    initialValue={user?.brokerData?.formalName}
                    rules={[{ required: true, message: handleRequiredMessage({ id: 'formalName', msg: 'Formal Name' }) }]}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'app.formalName',
                        defaultMessage: 'Formal Name',
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name={['brokerData', 'companyName']}
                    label={intl.formatMessage({
                      id: 'app.companyName',
                      defaultMessage: 'Company Name',
                    })}
                    initialValue={user?.brokerData?.companyName}
                    rules={[{ required: true, message: handleRequiredMessage({ id: 'companyName', msg: 'Company Name' }) }]}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'app.companyName',
                        defaultMessage: 'Company Name',
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col {...columnHalfLayout}>
                  <Form.Item
                    name={['brokerData', 'brokerShare']}
                    label={intl.formatMessage({
                      id: 'app.brokerShare',
                      defaultMessage: 'Broker Share',
                    })}
                    initialValue={user?.brokerData?.brokerShare}
                    rules={[{ required: true, message: handleRequiredMessage({ id: 'brokerShare', msg: 'Broker Share' }) }]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    name={['brokerData', 'brokerShareUnit']}
                    label={intl.formatMessage({
                      id: 'app.brokerShareUnit',
                      defaultMessage: 'Broker Share Unit',
                    })}
                    initialValue={user?.brokerData?.brokerShareUnit}
                    rules={[{ required: true, message: handleRequiredMessage({ id: 'brokerShareUnit', msg: 'Broker Share Unit' }) }]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({
                        id: 'app.select',
                        defaultMessage: 'Pleash Select...',
                      })}
                    >
                      <Select.Option value='percentage'>Percentage</Select.Option>
                      <Select.Option value='fixed'>Fixed</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null
        }
      </Form.Item>

      <Row type='flex' justify='space-around'>
        <Col {...columnHalfLayout}>
          <div style={styleSection}>{intl.formatMessage({ id: 'app.password', defaultMessage: 'Password' })}</div>
          <Form.Item
            name={['record', 'password']}
            label={
              userId
                ? intl.formatMessage({
                    id: 'app.newPassword',
                    defaultMessage: 'New password',
                  })
                : intl.formatMessage({
                    id: 'app.password',
                    defaultMessage: 'Password',
                  })
            }
            rules={[{ required: userId ? false : true, message: handleRequiredMessage({ id: 'password', msg: 'Password' }) }]}
          >
            <Input
              prefix={<LockOutlined style={{ fontSize: 13 }} />}
              type='password'
              placeholder={
                userId
                  ? intl.formatMessage({
                      id: 'app.newPassword',
                      defaultMessage: 'New password',
                    })
                  : intl.formatMessage({
                      id: 'app.password',
                      defaultMessage: 'Password',
                    })
              }
            />
          </Form.Item>
          <Form.Item
            name={['record', 'confirmPassword']}
            label={intl.formatMessage({
              id: 'app.confirmPassword',
              defaultMessage: 'Confirm password',
            })}
            dependencies={['record', 'password']}
            rules={[
              {
                required: userId ? false : true,
                message: handleRequiredMessage({ id: 'confirmPassword', msg: 'Confirm password' }),
              },
              { validator: checkConfirm },
            ]}
          >
            <Input
              prefix={<LockOutlined style={{ fontSize: 13 }} />}
              type='password'
              placeholder={intl.formatMessage({
                id: 'app.confirmPassword',
                defaultMessage: 'Confirm password',
              })}
            />
          </Form.Item>
        </Col>

        <Col {...columnHalfLayout}>
          <div style={styleSection}>{intl.formatMessage({ id: 'app.logo', defaultMessage: 'Logo' })}</div>
          <Form.Item noStyle shouldUpdate>
            {() => (
              <Form.Item
                name={['profileFile']}
                label={
                  <span>
                    {intl.formatMessage({ id: 'app.selectProfileLogo', defaultMessage: 'Select Profile Logo' })}
                    <Tooltip title='ภาพโปรไฟล์ของช่างภาพ'>
                      <InfoCircleOutlined style={{ fontSize: 'large', margin: '0 8px' }} />
                    </Tooltip>
                  </span>
                }
              >
                <Upload {...profileFileProps}>
                  {getFieldValue(['profileFile']) || user?.photographerProfile?.profileImageUrl ? (
                    <Button style={{ height: 'unset', padding: '8px' }}>
                      <img
                        src={getFieldValue(['profileFile'])?.thumbUrl ?? `${user?.photographerProfile?.profileImageUrl}?${now}`}
                        style={{ height: '150px' }}
                        alt=''
                      />
                    </Button>
                  ) : (
                    <Button>
                      <UploadOutlined /> {intl.formatMessage({ id: 'app.selectProfileLogo', defaultMessage: 'Select Profile Image' })}
                    </Button>
                  )}
                </Upload>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => (
              <Form.Item
                name={['logoFile']}
                label={
                  <span>
                    {intl.formatMessage({ id: 'app.selectOverlayLogo', defaultMessage: 'Select Overlay Logo' })}
                    <Tooltip title='โลโก้ที่จะติดบนภาพถ่าย'>
                      <InfoCircleOutlined style={{ fontSize: 'large', margin: '0 8px' }} />
                    </Tooltip>
                  </span>
                }
              >
                <Upload {...logoFileProps}>
                  {getFieldValue(['logoFile']) || user?.photographerProfile?.logoUrl ? (
                    <Button
                      style={{
                        height: 'unset',
                        padding: '8px',
                        background: 'gainsboro',
                      }}
                    >
                      <img
                        src={getFieldValue(['logoFile'])?.thumbUrl ?? `${user?.photographerProfile?.logoUrl}?${now}`}
                        style={{ maxHeight: '120px', maxWidth: '240px' }}
                        alt=''
                      />
                    </Button>
                  ) : (
                    <Button>
                      <UploadOutlined /> {intl.formatMessage({ id: 'app.selectOverlayLogo', defaultMessage: 'Select Overlay Logo' })}
                    </Button>
                  )}
                </Upload>
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type='flex' justify='space-around'>
        <Col {...columnHalfLayout}>
          <div style={styleSection}>{intl.formatMessage({ id: 'app.website', defaultMessage: 'Website' })}</div>
          <Form.Item
            name={['record', 'photographerProfile', 'website']}
            label={intl.formatMessage({
              id: 'app.website',
              defaultMessage: 'Website',
            })}
            initialValue={user?.photographerProfile?.website}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'app.website',
                defaultMessage: 'Website',
              })}
            />
          </Form.Item>
          <Form.Item
            name={['record', 'photographerProfile', 'facebookPageName']}
            label={intl.formatMessage({
              id: 'app.fBPageName',
              defaultMessage: 'FB Page Name',
            })}
            initialValue={user?.photographerProfile?.facebookPageName}
          >
            <Input placeholder='ThaiRun ฮับความสุขนักวิ่ง' />
          </Form.Item>
          <Form.Item
            name={['record', 'photographerProfile', 'facebookPageUsername']}
            label={intl.formatMessage({
              id: 'app.fBPageUsername',
              defaultMessage: 'FB Page Username',
            })}
            initialValue={user?.photographerProfile?.facebookPageUsername}
          >
            <Input addonBefore='facebook.com/' placeholder='thaidotrun' />
          </Form.Item>
        </Col>

        <Col {...columnHalfLayout}>
          <div style={styleSection}>{intl.formatMessage({ id: 'app.bankAccount', defaultMessage: 'Bank Account' })}</div>
          {user?.recipientId || linkBankAccount ? (
            <>
              <Form.Item
                name={['record', 'bankAccount', 'brand']}
                label={intl.formatMessage({
                  id: 'app.bank',
                  defaultMessage: 'Bank',
                })}
                initialValue={user?.bankAccount?.brand}
                rules={[{ required: true, message: handleRequiredMessage({ id: 'bank', msg: 'Bank' }) }]}
              >
                <Select style={{ width: 200 }}>
                  {supportedBankList.map((bankId) => (
                    <Select.Option key={bankId} value={bankId}>
                      {intl.formatMessage({ id: `app.banks.${bankId}` })}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={['record', 'bankAccount', 'number']}
                label={intl.formatMessage({
                  id: 'app.accountNumber',
                  defaultMessage: 'Account Number',
                })}
                initialValue={user?.bankAccount?.number}
                rules={[{ required: true, message: handleRequiredMessage({ id: 'accountNumber', msg: 'Account Number' }), len: 10 }]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'app.accountNumber',
                    defaultMessage: 'Account Number',
                  })}
                />
              </Form.Item>
              <Form.Item
                name={['record', 'bankAccount', 'name']}
                label={intl.formatMessage({
                  id: 'app.accountName',
                  defaultMessage: 'Account Name',
                })}
                initialValue={user?.bankAccount?.name}
                rules={[{ required: true, message: handleRequiredMessage({ id: 'accountName', msg: 'Account Name' }) }]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'app.accountName',
                    defaultMessage: 'Account Name',
                  })}
                />
              </Form.Item>
              <Form.Item
                name={['record', 'bankAccount', 'passbookImageUrl']}
                label={intl.formatMessage({ id: 'app.passbookImageUrl', defaultMessage: 'Bank Account Book' })}
                initialValue={user?.bankAccount?.passbookImageUrl}
                rules={[{ required: true, message: handleRequiredMessage({ id: 'passbookImageUrl', msg: 'Bank Account Book' }) }]}
              >
                <CloudImageUploader provider='gs' path='passbooks' />
              </Form.Item>
            </>
          ) : (
            <Button onClick={handleLinkBankAccount}>{intl.formatMessage({ id: 'app.linkBankAccount', defaultMessage: 'Link Bank Account' })}</Button>
          )}
        </Col>
      </Row>
    </Form>
  )
})

export default EditUser
