import React, { useState } from 'react'
import { Button, Modal, message, DatePicker, Form, Upload, InputNumber } from 'antd'
import { useMutation } from '@apollo/client'
import createOrUpdateManualTransferMutation from '~/graphql/mutations/createOrUpdateManualTransfer.gql'
import brokerEventsQuery from '~/graphql/queries/brokerEvents.gql'
import CloudImageUploader from '~/components/CloudImageUploader'
import moment from 'moment-timezone'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const BrokerTransferButton = ({ event }) => {
  const [form] = Form.useForm()
  const { getFieldsValue, resetFields } = form
  const [createOrUpdateManualTransfer] = useMutation(createOrUpdateManualTransferMutation)
  const [open, setOpen] = useState(false)
  const handleOk = async () => {
    const values = getFieldsValue(true)
    const {
      record: { completedAt: $completedAt, ...record },
    } = values
    const completedAt = $completedAt.startOf('day')
    try {
      await createOrUpdateManualTransfer({
        variables: {
          record: {
            completedAt,
            eventId: event._id,
            userId: event.brokerId,
            userRole: 'broker',
            ...record,
          },
        },
        refetchQueries: [
          {
            query: brokerEventsQuery,
            ...(event.brokerId && {
              variables: { brokerId: event.brokerId },
            }),
          },
        ],
      })
      message.success('success', 4)
    } catch (err) {
      message.error(err)
    } finally {
      resetFields()
      setOpen(false)
    }
  }
  const handleClick = () => setOpen(true)
  const handleRemoveSlip = async () => {
    const values = getFieldsValue(true)
    const { record } = values
    await createOrUpdateManualTransfer({
      variables: {
        record: {
          ...record,
          slipUrl: null,
        },
      },
    })
    setOpen(false)
  }
  const { brokerTransfer } = event
  return (
    <>
      {brokerTransfer?.slipUrl ? (
        <Upload
          defaultFileList={[{ uid: brokerTransfer?.slipUrl, url: brokerTransfer?.slipUrl, name: 'ดาวน์โหลดสลิป' }]}
          showUploadList={{ showRemoveIcon: true, showDownloadIcon: true }}
          onRemove={handleRemoveSlip}
        />
      ) : (
        <Button onClick={handleClick}>แนบหลักฐาน</Button>
      )}
      <Modal
        title={event.title}
        open={open}
        closable
        // onCancel={(e) => console.log(e, e.button, e.target)}
        onOk={handleOk}
        onCancel={() => setOpen(false)}
        maskClosable
      >
        <Form form={form}>
          <Form.Item {...formItemLayout} label='ส่วนแบ่ง Broker'>
            {event.totalBrokerShare?.toFixed(2)}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['record', 'amount']}
            label='ยอดเงินโอน'
            initialValue={brokerTransfer?.amount ?? Number.parseFloat(event.totalBrokerShare?.toFixed(2))}
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber min={1} disabled={!!brokerTransfer?.completedAt} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['record', 'completedAt']}
            label='วันที่โอนเงิน'
            initialValue={brokerTransfer?.completedAt && moment(brokerTransfer?.completedAt)}
            rules={[{ required: true, message: '' }]}
          >
            <DatePicker disabledDate={(current) => current.isSameOrBefore(brokerTransfer?.shareEndsAt)} disabled={!!brokerTransfer?.completedAt} />
          </Form.Item>
          <Form.Item {...formItemLayout} name={['record', 'slipUrl']} label='แนบสลิป' rules={[{ required: true, message: '' }]}>
            <CloudImageUploader provider='gs' path='documents' accept='.jpg,.jpeg,.png' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default BrokerTransferButton
